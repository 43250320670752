import React from 'react';

import { DateTime } from 'luxon';

import LoadingCenter from './LoadingCenter';

import { getCourses } from './ajax';


class LearnerCourseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading',
      courses: [],
    };
    this.navigate = this.navigate.bind(this);
  }

  async componentDidMount() {
    try {
      // get courses from server
      let courses = await getCourses();
      console.log('[pi3] Fetched courses?', courses);

      this.setState({
        state: 'loadeddd',
        courses: courses,
      });
    }
    catch(e) {
      console.error('[LearnerCourseList] Error in componentDidMount()', e);
    }
  }

  navigate(courseId) {
    console.log('[LearnerCourseList] Navigating...', { courseId });
    window.location.replace(`/course/${courseId}`);
  }
 
  render() {

    if (this.state.state === 'loading') {
      return (
        <div className='pi3courseList pi3narrow'>
          <h1>Your Enrolled Courses</h1>
          <br />
          <br />
          <LoadingCenter />
        </div>
      );
    }

    let courses = [];

    for (let course of this.state.courses) {
      if (course.isAvailable === true) {
        courses.push(
          // <a href={`/course/${course.id}`} key={course.id}>
          <div key={course.id} className='pi3courseListItem' onClick={() => this.navigate(course.id)}>
            <div className="pi3courseListItemIcon">
              {course.coverImage &&
                <img
                  src={course.coverImage.url}
                  alt={course.name}
                  />
              }
            </div>
            <div className="pi3courseListItemContent">
              <div className="pi3courseListItemHeader">
                <div className="left">
                  <h3>{course.name}</h3>
                </div>
                <div className="right">
                  <h3>{course.progress.completedPercent}%</h3>
                </div>
              </div>
              <div className="pi3courseListItemProgressBar">
                <div className="pi3courseListItemProgressBarInner" style={{width: `${course.progress.completedPercent}%`}}>
                </div>
              </div>
              <div className="pi3courseListItemActions">
                <div>
                  {course.expiresAt &&
                    <span> {DateTime.fromISO(course.expiresAt).diffNow('days').toFormat('d')} days remaining</span>
                  }
                </div>
                {/* <div>
                  <a href={`/course/${course.id}`}>Continue</a>
                </div> */}
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className='pi3courseList pi3narrow'>
        <h1>Your Enrolled Courses</h1>
        <br />
        {courses}
        {courses.length === 0 &&
        <div>
          You are not currently enrolled into any courses.
        </div>
        }
      </div>
    );
 
  }
}

export default LearnerCourseList;
