import React from 'react';

import { loginSendEmail, login } from './ajax';
import * as session from './session';

import validation from './validation';
import { getQueryAttribute } from './helpers';

import { FormCheckbox } from './FormElements';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'enterEmail', // 'enterEmail', 'sendingEmail', 'enterConfirmationCode', 'sendingConfirmationCode', 'userUnknown'

      email: '', // email address
      emailValidation: false, // true when user attempts submitting email
      emailValid: false, // true when email is in valid format

      remember: false,

      confirmationCode: '',
      confirmationCodeValidation: false,
      confirmationCodeValid: false,

      message: '',
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onRememberChange = this.onRememberChange.bind(this);
    this.submit = this.submit.bind(this);
    this.sendConfirmationCode = this.sendConfirmationCode.bind(this);
    this.retry = this.retry.bind(this);
  }
  onEmailChange(ev) {
    let email = ev.target.value;
    let emailValid = validation.validateEmail(email, true);
    this.setState({ email, emailValid });
  }
  onRememberChange(remember) {
    this.setState({ remember });
  }
  async componentDidMount() {
    try {
      let localUser = session.getUser();
      console.log('[Login] Fetched local user?', { localUser });
      // if (localUser) {
      //   console.log('[Login] User is logged in, redirecting to /courses...');
      //   location.replace('/courses');
      //   // TODO fix this: localUser can be present when user is no longer logged in
      // }

      // TODO check if we passed email param via url (redirection from somewhere to url)
      let encodedEmail = getQueryAttribute(window.location.search, 'e', true);
      if (encodedEmail !== null && encodedEmail.length > 0 ) {
        console.log('[Login] Email provided in url.', { encodedEmail });

        let decodedEmail = decodeURIComponent(encodedEmail);
        console.log(`[Login] Decoded embedded email?`, decodedEmail);

        let emailValid = validation.validateEmail(decodedEmail, true);
        this.setState({ email: decodedEmail, emailValid });
      }

    }
    catch(e) {
      console.error(`[Login] Error in componentDidMount()`, e);
    }
  }
  async sendConfirmationCode() {
    try { 
      // start validating email
      this.setState({ emailValidation: true });

      // console.log('[Login] Is email valid?', this.state.emailValid); // debug

      if (!this.state.emailValid) {
        this.setState({
          state: 'enterEmail',
          message: 'email-invalid',
        });
        return;
      }

      // send to server
      this.setState({ state: 'sendingEmail' });
      let result = await loginSendEmail(this.state.email);
      if (result.success) {
        // user exists, proceed to confirmation code entry
        this.setState({ state: 'enterConfirmationCode' });
      }
      else if (result.message === 'login-failed-teachable') {
        // console.log('this is a teachable user, we should redirect to productinstitute login');
        location.replace('https://productinstitute.com/sign_in');
      }
      else if (result.message === 'login-failed-user-unknown') {
        // user unknown, inform the user to do something about it 
        //   - for individuals: please make a purchase, this will create an account
        //   - for teams: please contact your team manager
        this.setState({ state: 'userUnknown' });
      }
      else {
        // user does not exist anywhere, but let's continue to confirmation code part
        this.setState({ state: 'enterConfirmationCode' });
      }

    } catch(e) {
      console.error('Error submitting user e-mail.', e);
    }
  }

  async retry() {
    // mistyped email
    this.setState({
      state: 'enterEmail',
      // email: '',
      emailValidation: true,
      emailValid: validation.validateEmail(this.state.email, true),
      // remember: false,
      confirmationCode: '',
      confirmationCodeValidation: false,
      confirmationCodeValid: false,
      // message: '',
    });
  }

  async submit() {
    try {

      this.setState({ state: 'sendingConfirmationCode' });

      let loggy = await login(this.state.email, this.state.confirmationCode);
      console.log('[Login] Did we login?', loggy);
      if (loggy.success) {
        console.log('[Login] Successful login, we should redirect here...');

        // publish user change
        await session.setUser(loggy.user);

        // check if we have redirect URL encoded in URL
        let encodedUrl = getQueryAttribute(window.location.search, 'r');
        if (encodedUrl) {
          console.log('we have encoded url?', encodedUrl);
          let decodedUrl = decodeURIComponent(encodedUrl);
          console.log('we have decoded url?', decodedUrl);
          location.replace(decodedUrl);
          return;
        }

        // redirect to /courses
        location.replace('/courses');

      }
      else {
        // wrong code; display error message
        this.setState({
          state: 'enterConfirmationCode',
          message: loggy.message,
        });
      }
    }
    catch(e) {
      console.error('[Login] Error submitting login data.', e);
    }
  }
  render() {

    if (this.state.state === 'enterEmail' ||
        this.state.state === 'sendingEmail') {

      let emailStyle = '';
      if (this.state.emailValidation && !this.state.emailValid) {
        emailStyle = 'error';
      }

      return (
        <div className='loginTwoStep'>
          <h1>Login to Product Institute</h1>
          <br />
          <p>
            Enter your email below:
          </p>
          <div className='pi3form'>
            <div className="formRow">
              {
                this.state.state === 'enterEmail' &&
                <span>
                  <div className="formRow">
                    <input
                      type="email" id="email" name="email" required
                      value={this.state.email}
                      placeholder='Email'
                      onChange={this.onEmailChange}
                      className={emailStyle} />
                  </div>
                  {/* <div className="formRow">
                    <FormCheckbox isChecked={this.state.remember} isDisabled={false} label='Remember this device' onChange={this.onRememberChange} />
                  </div> */}
                </span>
              }
              {
                this.state.state === 'sendingEmail' &&
                <span>
                  <div className="formRow">
                    <input
                      type="email" id="email" name="email" required disabled
                      value={this.state.email} />
                  </div>
                  {/* <div className="formRow">
                    <FormCheckbox isChecked={this.props.remember} isDisabled={true} label='Remember this device' />
                  </div> */}
                </span>
              }
            </div>

            <br />

            <div className="formRow">
              {
                this.state.state === 'enterEmail' &&
                <button onClick={this.sendConfirmationCode} className="pi3button stretch">Login</button>
              }
              {
                this.state.state === 'sendingEmail' &&
                <button className="pi3button stretch" disabled>Checking...</button>
              }
            </div>

            <br />

            {/* <div className="formRow">
              <p>
                Having login trouble? Please use the <a href='/p/contact-us'>contact form</a> and let us know!
              </p>
            </div> */}



          </div>

        </div>
      );

    }

    else if (this.state.state === 'enterConfirmationCode' ||
             this.state.state === 'sendingConfirmationCode' || 
             this.state.state === 'userUnknown') {

      return (
        <div className='pi3shadowBox loginTwoStep'>

          <h1>Login to Product Institute</h1>

          {/* <p>
            Sorry, we don't recognise this email address.
            <br />
            If you haven't joined Product Institute yet, please purchase a course or contact your team manager for access instructions.
            <br />
            Having login trouble? Use the <a href='/p/contact-us'>contact form</a> and let us know!
          </p> */}

          <br />

          <p>
            A one-time code has been sent to <a className='redline' onClick={this.retry}>{this.state.email}</a>, please enter it below to login - no password needed.
          </p>

          <br />

          {/* {this.state.state !== 'userUnknown' &&
            <p>
              A one-time code has been sent to <a className='redline' onClick={this.retry}>{this.state.email}</a>. Enter it below to login (no password needed!).
            </p>
          }

          {this.state.state === 'userUnknown' &&
            <p>
              Sorry, we don't recognise this email address.
              <br />
              If you haven't joined Product Institute yet, please purchase a course or contact your team manager for access instructions.
              <br />
              Having login trouble? Use the <a href='/p/contact-us'>contact form</a> and let us know!
            </p>
          } */}

          <div className='pi3form'>
            {
              (this.state.state === 'enterConfirmationCode' || this.state.state === 'userUnknown') && 
              <input
                type="text" id="confirmationCode" name="confirmationCode" required
                className="code" maxLength="6" value={this.state.confirmationCode}
                placeholder="Login Code"
                onChange={ev => this.setState({ confirmationCode: ev.target.value })} />
            }
            {
              this.state.state === 'sendingConfirmationCode' && 
              <input
                type="text" id="confirmationCode" name="confirmationCode" required disabled
                value={this.state.confirmationCode} />
            }
            <div className="formRow submitRow">
              {
                (this.state.state === 'enterConfirmationCode' || this.state.state === 'userUnknown') && 
                <button onClick={this.submit} className="pi3button stretch">Log in</button>
              }
              {
                this.state.state === 'sendingConfirmationCode' && 
                <button className="pi3button stretch" disabled>Logging in...</button>
              }
            </div>
          </div>

          <p>
            If you haven't created an account with us yet, you can do that by making a <a href='/checkout'>purchase</a> or follow your company's onboarding instructions to join Product Institute.
          </p>

        </div>
      );

    }
 
  }
}

export default Login;