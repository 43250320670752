'use strict';

import React from 'react';


// <FormYesNo value={isChecked} onChange={} />
class FormYesNo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { value, onChange } = this.props;

    return (
      <div className='pi2YesNo'>
        <div className='radioButtonOuter' onClick={() => onChange(true)}>
          <div className='radioButtonContainer'>
            <div className={`radioButton ${value ? 'active' : ''}`} ></div>
          </div>
          <div className='radioButtonLabel'>Yes</div>
        </div>
        <div className='radioButtonOuter' onClick={() => onChange(false)}>
          <div className='radioButtonContainer'>
            <div className={`radioButton ${value ? '' : 'active'}`} ></div>
          </div>
          <div className='radioButtonLabel'>No</div>
        </div>
      </div>
    );

  }
}

export default FormYesNo;