import React from 'react';

import enums from './enums';
import { postLearnerActivity, getCourse } from './ajax';

import CourseBlockText from './CourseBlockText';
import CourseBlockVideo from './CourseBlockVideo';
import CourseBlockImage from './CourseBlockImage';
import CourseBlockYouTube from './CourseBlockYouTube';
import CourseBlockDocument from './CourseBlockDocument';
import CourseBlockCustomCode from './CourseBlockCustomCode';



class LearnerLesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompleteLessonEnabled: false,
    };
    this.wrapperRef = React.createRef();
    this.detectBottom = this.detectBottom.bind(this);
    this.completeLesson = this.completeLesson.bind(this);
    this.completeLessonAndNavigate = this.completeLessonAndNavigate.bind(this);
    this.postLessonView = this.postLessonView.bind(this);
    this.postLessonComplete = this.postLessonComplete.bind(this);
  }

  async componentDidMount() {
    try {

      // check if already at bottom of lesson
      this.detectBottom();

      // listen to scroll events
      let scrollable = document.getElementById('pi3lessonScrollable');
      scrollable.addEventListener('scroll', this.detectBottom);

      // load course for reasons (so we can 'next lesson')
      let course = await getCourse(this.props.lesson.section.course.id);
      this.setState({ course });

      // after 1 minute send 'lesson view' activity
      setTimeout(() => {
        console.log('[LearnerLesson] Posting lesson view...');
        this.postLessonView();
      }, 60 * 1000);

      // after 3 minutes send 'lesson complete' activity
      setTimeout(() => {
        console.log('[LearnerLesson] Posting lesson complete...');
        this.postLessonComplete();
      }, 3 * 60 * 1000);

    }
    catch(e) {
      console.error('[LearnerLesson] Error in componentDidMount()', e);
    }
  }

  async componentWillUnmount() {
    try {
      // stop listening to scroll events
      let scrollable = document.getElementById('pi3lessonScrollable');
      scrollable.removeEventListener('scroll', this.detectBottom);
    }
    catch(e) {
      console.error('[LearnerLesson] Error in componentWillUnmount()', e);
    }
  }

  async completeLesson() {
    try {
      console.log('[LearnerLesson] Completing lesson...');

      // window.lesson = this.props.lesson; // debug
      // window.course = this.state.course; // debug
  
      // send 'lesson complete' event
      await this.postLessonComplete();
    }
    catch(e) {
      console.error('[LearnerLesson] Error in completeLesson()', e);
    }

  }

  async completeLessonAndNavigate() {
    try {

      await this.completeLesson();

      // decide next lesson id/url
      let lessons = this.state.course.sections.map(s => s.lessons.map(l => l)).flat();
      let lesson = lessons.find(l => l.id === this.props.lesson.id);
      let currentLessonIndex = lessons.indexOf(lesson);

      let nextLessonUrl = `/course/${this.props.lesson.section.course.id}/certificate`;

      // if there is a next lesson, navigate to it
      if (currentLessonIndex + 1 < lessons.length) {
        let nextLesson = lessons[currentLessonIndex + 1];
        if (nextLesson.type === enums.CourseLesson.Type.Text ||
            nextLesson.type === enums.CourseLesson.Type.Video) {
          nextLessonUrl = `/course/${this.props.lesson.section.course.id}/lesson/${nextLesson.id}`;
        }
        else if (nextLesson.type === enums.CourseLesson.Type.Quiz) {
          nextLessonUrl = `/course/${this.props.lesson.section.course.id}/quiz/${nextLesson.id}`;
        }
        else if (nextLesson.type === enums.CourseLesson.Type.Chatflow) {
          nextLessonUrl = `/course/${this.props.lesson.section.course.id}/chatflow/${nextLesson.id}`;
        }
      }

      //this.setState({ nextLessonUrl });

      // navigate to next lesson
      window.location.replace(nextLessonUrl);
      // console.log('replacing window lication?', nextLessonUrl); // debug
    }
    catch(e) {
      console.error('[LearnerLesson] Error in completeLessonAndNavigate()', e);
    }
  }

  detectBottom() {
    // INPROG detect scrolling to bottom (or already being on bottom on pageload), so we can enable 'continue'

    if (this.state.isCompleteLessonEnabled) return;

    console.log('[LearnerLesson] Detecting bottom...');
    let isNearlyBottom = this.wrapperRef.current.getBoundingClientRect().bottom <= window.innerHeight;
    // console.log('nearly bottom?', isNearlyBottom);

    if (isNearlyBottom) {
      this.setState({ isCompleteLessonEnabled: true });
    }


    return ;


  }

  async postLessonView() {
    try {
      console.log('[LearnerLesson] Posting lesson view...');
      let activity = await postLearnerActivity(
        enums.UserActivity.Type.LessonView, this.props.lesson.id);
    }
    catch(e) {
      console.error('[LearnerLesson] Error in postLessonView()', e);
    }
  }

  async postLessonComplete() {
    try {
      console.log('[LearnerLesson] Posting lesson complete...');
      let activity = await postLearnerActivity(
        enums.UserActivity.Type.LessonComplete, this.props.lesson.id);
    }
    catch(e) {
      console.error('[LearnerLesson] Error in postLessonComplete()', e);
    }
  }

  render() {

    let lesson = this.props.lesson;

    let blocks = [];
    for (let block of lesson.blocks) {

      if (block.type === enums.CourseBlock.Type.Text) {
        blocks.push(
          <CourseBlockText
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Image) {
        blocks.push(
          <CourseBlockImage
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Video) {
        blocks.push(
          <CourseBlockVideo
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.YouTube) {
        blocks.push(
          <CourseBlockYouTube
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Document) {
        blocks.push(
          <CourseBlockDocument
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.CustomCode) {
        blocks.push(
          <CourseBlockCustomCode
            block={block}
            key={block.id}
          />
        );
      }

      // unknown type
      else {
        blocks.push(
          <div style={{ border: '1px solid #f00' }} key={block.id}>
            Unknown Block Type {block.id}
          </div>
        );
      }

    }


    let completeButton = (
      <button className='pi3buttonSmall' disabled>Complete and Continue</button>
    );
    if (this.state.isCompleteLessonEnabled && this.state.course) {
      completeButton = (
        <button className='pi3buttonSmall' onClick={this.completeLessonAndNavigate}>Complete and Continue</button>
      );
    }


    return (
      <div className='pi3lesson pi3narrow'
        ref={this.wrapperRef}
        onScroll={this.detectBottom}
      >

        <div className="pi3lessonHeading">
          <h3>
            {getTypeIconMonochrome(lesson.type)}
            {lesson.name}
          </h3>
          {/* <div style={{ display: 'flex' }}>
            {completeButton}
          </div> */}
        </div>

        {blocks}

        <div style={{ margin: '20px 0 40px 0', display: 'flex', justifyContent: 'flex-end' }}>
          {completeButton}
        </div>

      </div>
    );
 
  }
}

export default LearnerLesson;



function getTypeIconMonochrome(type) {
  if (type === enums.CourseLesson.Type.Text) {
    return (
      <div style={{ display: 'inline-block', padding: '3px 10px 0 0' }}>
        <div className="icon-lesson-text-monochrome"></div>
      </div>
    );
  }
  else if (type === enums.CourseLesson.Type.Video) {
    return (
      <div style={{ display: 'inline-block', padding: '3px 10px 0 0' }}>
        <div className="icon-lesson-video-monochrome"></div>
      </div>
    );
  }
  else if (type === enums.CourseLesson.Type.Quiz) {
    return (
      <div style={{ display: 'inline-block', padding: '3px 10px 0 0' }}>
        <div className="icon-lesson-quiz-monochrome"></div>
      </div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chatflow) {
    return (
      <div style={{ display: 'inline-block', padding: '3px 10px 0 0' }}>
        <div className="icon-lesson-text-monochrome"></div>
      </div>
    );
  }
}
