
import React from 'react';
import {GBox} from '../../src/pi3-frontend/generics/GBox/GBox.jsx';
import {GButton} from '../../src/pi3-frontend/generics/GButton/GButton.jsx';
import {GFlexItem} from '../../src/pi3-frontend/generics/GFlex/GFlexItem.jsx';
import {GFlex} from '../../src/pi3-frontend/generics/GFlex/GFlex.jsx';
import {GInputText} from '../../src/pi3-frontend/generics/GInputText/GInputText.jsx';
import {GRawHTML} from '../../src/pi3-frontend/generics/GRawHTML/GRawHTML.jsx';
import {marked} from 'marked';
import {useAPI} from '../../src/pi3-frontend/API.js';
import {useEffect} from 'react';
import {useState} from 'react';

export const LearnerChatflow = function (props) {

  const api = useAPI();

  let userInputEl = null;
  const [userInputValue, setUserInputValue] = useState('');
  const [userInputVisible, setUserInputVisible] = useState(false);

  const [sessionID, setSessionID] = useState('');
  const [messages, setMessages] = useState([]);
  const [choices, setChoices] = useState([]);
  const [end, setEnd] = useState(false);

  useEffect(() => {
    (async () => {
      const chatflowInfo = await api.post({
        url: 'https://ai.productinstitute.com/api/session-begin',
        body: {
          chatflowID: props.lesson.chatflowId,
        },
      });
      setSessionID(chatflowInfo.sessionID);
      setMessages([]);
      for (const msg of chatflowInfo.messages)
        setMessages(messages => [...messages, msg]);
      setChoices(chatflowInfo.choices);
      setEnd(chatflowInfo.end);
      setUserInputVisible(true);
    })();
  }, []);

  return (<>
    <GBox>
      {messages.map((msg, i) => (
        <GFlex key={i}
          style={{
            height: 'auto',
            gap: 'var(--spacing-1)',
          }}
         >
          {!!msg.name ? (<>
            <GFlexItem
              style={{
                flex: 'none',
                alignSelf: 'flex-start',
                width: 'auto',
                height: 'auto',
              }}
            >
              <p>{msg.name}:</p>
            </GFlexItem>
          </>) : ''}
          <GFlexItem
            style={{
              width: 'auto',
              height: 'auto',
            }}
          >
            <GRawHTML html={marked.parse(msg.content)} />
          </GFlexItem>
        </GFlex>
      ))}
      {(() => {
        if (end) {
          return (<>
            Your session has ended.
          </>);
        }
        if (!userInputVisible) {
          return (<>
            ...
          </>);
        }
        if (choices.length > 0) {
          return (<>
            {choices.map((choice, i) => (
              <GButton
                key={i}
                style={{
                  width: 'auto',
                  height: 'auto',
                  margin: 'var(--spacing-1)',
                }}
                onClick={async (e) => {
                  setUserInputVisible(false);
                  setMessages(messages => [...messages, {
                    role: 'user',
                    name: 'You',
                    content: choice,
                  }]);
                  userInputEl?.focus?.();
                  const chatflowInfo = await api.post({
                    url: 'https://ai.productinstitute.com/api/session-provide-input',
                    body: {
                      sessionID: sessionID,
                      content: choice,
                    },
                  });
                  for (const msg of chatflowInfo.messages)
                    setMessages(messages => [...messages, msg]);
                  setChoices(chatflowInfo.choices);
                  setEnd(chatflowInfo.end);
                  setUserInputVisible(true);
                  userInputEl?.focus?.();
                }}
              >
                {choice}
              </GButton>
            ))}
          </>);
        }
        return (<>
          <GInputText
            onElement={(refEl) => { userInputEl = refEl; }}
            value={userInputValue}
            onChange={(val) => { setUserInputValue(val); }}
            onSubmit={async (val) => {
              setUserInputVisible(false);
              const providedInput = val;
              setUserInputValue('');
              setMessages(messages => [...messages, {
                role: 'user',
                name: 'You',
                content: providedInput,
              }]);
              userInputEl?.focus?.();
              const chatflowInfo = await api.post({
                url: 'https://ai.productinstitute.com/api/session-provide-input',
                body: {
                  sessionID: sessionID,
                  content: providedInput,
                },
              });
              for (const msg of chatflowInfo.messages)
                setMessages(messages => [...messages, msg]);
              setChoices(chatflowInfo.choices);
              setEnd(chatflowInfo.end);
              setUserInputVisible(true);
              userInputEl?.focus?.();
            }}
          />
        </>);
      })()}
    </GBox>
  </>);

};
