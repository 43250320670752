
'use strict';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';


import { getProducts, repurchase } from './ajax';
import LoadingCenter from './LoadingCenter';

const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;
const stripePromise = loadStripe(STRIPE_PUBKEY);


const appearance = {
  theme: 'stripe',
};
// const options = {
//   clientSecret,
//   appearance,
// };


class ConceptRepurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading',
      products: [],
      stripeClientSecret: null,
    };
    this.checkout = this.checkout.bind(this);
  }
  async componentDidMount() {
    try {
      // load products
      let products = await getProducts();
      console.log(`[Repurchase] Fetched products.`, products);
      this.setState({ stage: 'select', products: products.products });
    }
    catch(e) {
      console.error(`[Repurchase] Error in componentDidMount()`, e);
    }
  }
  async checkout() {
    try {
      // TODO construct body
      let body = {
        terms: true,
        invoice: false,
        invoiceName: '',
        invoiceEmail: '',
        companyName: '',
        companyAddress: '',
        companyVAT: '',
        itemIds: [1, 2, 3],
        couponCode: 'THINKING',
      };
      // TODO make API call
      let response = await repurchase(body);

      console.log(`[Repurchase] Received response?`, response);

      this.setState({
        stage: 'card',
        stripeClientSecret: response.clientSecret,
      });

    }
    catch(e) {
      console.error(`[Repurchase] Error in checkout()`, e);
    }
  }
  render() {

    if (this.state.stage === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    if (this.state.stage === 'select') {

      let courses = this.state.products.map(p => 
        <div key={p.id}>{p.name}</div>
      );

      return (
        <div>
          Course selection goes here.
          <br />
          {courses}
          <br />
          <button className='pi3button' onClick={this.checkout}>Checkout</button>
        </div>
      );
    }

    if (this.state.stage === 'card') {
      return (
        <div>
          <Elements
            options={{
              clientSecret: this.state.stripeClientSecret,
              appearance: appearance
            }}
            stripe={stripePromise}>
            <CheckoutForm clientSecret={this.state.stripeClientSecret}/>
          </Elements>
        </div>
      );
    }



    return (
      <div>
        repurchase component in unknown state
      </div>
    );
  }
}

export default ConceptRepurchase;




function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
      case 'succeeded':
        setMessage('Payment succeeded!');
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        setMessage('Your payment was not successful, please try again.');
        break;
      default:
        setMessage('Something went wrong.');
        break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'http://localhost:3000',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className='pi3button'>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}