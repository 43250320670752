
import DOMPurify from 'dompurify';
import React from 'react';

export const GRawHTML = function (props) {

  return (<>
    <div
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        ...props?.style,
      }}
      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props?.html)}}
    />
  </>);

};
