'use strict';

function getRandomHex(length = 6, uppercase = true) {
  let t = Math.random().toString(16).slice(2, length + 2);
  return uppercase ? t.toUpperCase() : t;
}

// return shortened string if over maxLength characters
function shortenString(string, maxLength) {
  if (string && string.length > maxLength) {
    string = string.substring(0, maxLength-1) + '…';
  }
  return string;
}

// return value of query attribute from provided full url
function getQueryAttribute(search, attribute, decode = false) {
  let query = search.substring(1);
  let pairs = query.split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    if (pair[0] === attribute) {
      if (decode) {
        return decodeURI(pair[1]);
      }
      return pair[1];
    }
  }
  return null;
}

// TODO this is terrible, fix me later
// https://2ality.com/2012/08/underscore-extend.html
function shallowCopy(target) {
  var sources = [].slice.call(arguments, 1);
  for (let source of sources) {
    for (let key of Object.keys(source)) {
      target[key] = source[key];
    }
  }
  return target;
}

function toPrettyDollars(centAmount, prefix = '$') {
  let amount = centAmount / 100;
  let stringy = amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  if (prefix !== '$') {
    stringy = stringy.replace('$', prefix);
  }
  return stringy;
}

function toPrettyDollarsRound(centAmount) {
  let amount = centAmount / 100;
  let stringy = `${amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
  // if ends with '.00', remove it
  if (stringy.endsWith('.00')) {
    stringy = stringy.substring(0, stringy.length - 3);
  }
  return stringy;
}

function padZeroes(number, size) {
  if (size > 12) {
    throw new Error('padZeroes() does not support numbers that big.');
  }
  return ('000000000000' + number).substr(-size);
}

function getInvoiceFriendlyId(invoiceId) {
  let idFriendly = `IND-${padZeroes(invoiceId, 4)}`;
  return idFriendly;
}

function isArrayEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
function moveArrayElement(array, indexOld, indexNew) {
  if (indexNew >= array.length) {
    var k = indexNew - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }
  array.splice(indexNew, 0, array.splice(indexOld, 1)[0]);
  return array; // for testing
}

function sleep(miliseconds) {
  return new Promise(resolve => setTimeout(resolve, miliseconds));
}

export {
  getRandomHex,
  getQueryAttribute,
  shortenString,
  shallowCopy,
  toPrettyDollars,
  toPrettyDollarsRound,
  getInvoiceFriendlyId,
  isArrayEqual,
  moveArrayElement,
  sleep,
};