import React from 'react';

class NewPurchaseFlowSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    let individualClass = `purchaseFlowSelectIndividuals ${this.props.flow === 'individuals' ? 'purchaseFlowSelectItem purchaseFlowSelectItemActive' : 'purchaseFlowSelectItem'}`;
    let teamsClass = `purchaseFlowSelectTeams ${this.props.flow === 'teams' ? 'purchaseFlowSelectItem purchaseFlowSelectItemActive' : 'purchaseFlowSelectItem'}`;

    let pfs = (
      <div className='purchaseFlowSelect'>
        <div className={individualClass}>
          <h2>For Individuals</h2>
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/purchase-flow-individuals@2x.png' alt='For Individuals' />
          <p>I’m an <strong>individual</strong> learner, looking for self-paced courses</p>
          <button className='pi4button pi2uppercase' onClick={() => this.props.onChange('individuals')}>Choose your Courses</button>
        </div>

        <div className={teamsClass}>
          <h2>For Teams</h2>
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/purchase-flow-teams@2x.png' alt='For Teams' />
          <p>I’m a manager looking for training for my <strong>whole team</strong>.</p>
          <button className='pi4button pi2uppercase' onClick={() => this.props.onChange('teams')}>Choose your Team Plan</button>
        </div>
      </div>
    );
    return pfs;
  }
}

export default NewPurchaseFlowSelect;