'use strict';

import React from 'react';

import validation from './validation';
import enums from './enums';

import LoadingCenter from './LoadingCenter';
import { shallowCopy, getQueryAttribute, toPrettyDollars, toPrettyDollarsRound } from './helpers'; 


import {
  // getCompanyProducts2,
  submitCompanyOnboarding2
} from './ajax-vintage'; 

import {
  getProductsTeams,
} from './ajax';



import { emitPageView, emitTeamsWireInvoiceGenerated } from './analytics';


import NewPurchaseFlowCloud from './NewPurchaseFlowCloud';
import NewPurchaseFlowSelect from './NewPurchaseFlowSelect';
import CompanyOnboarding2ThankYouWire from './CompanyOnboarding2ThankYouWire';

const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;


var stripe = null;
if (typeof Stripe !== 'undefined') {
  stripe = Stripe(STRIPE_PUBKEY);
} else {
  console.warn('[CompanyOnboarding] Stripe library not loaded.');
}

class CompanyOnboarding2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // loading, planSelect, planConfirm, invoice, wireInvoiceConfirm
      planForm: {
        licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations,
        seats: 1,
        coachingPackages: 0,
        validate: false,
      },
      planFormTree: null,
      invoiceForm: {
        name: '', // Full Name e.g. John Smith
        email: '', // Email Address name@company.com
        companyName: '',
        companyAddress: '',
        companyVAT: '',
        seats: 1,
        coachingPackages: 0,
        paymentMethod: enums.CompanyOnboarding.PaymentMethod.Stripe,
        licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations,
        message: '', // Message What would you like to discuss or what is your question?
        validate: false,
      },
      invoiceFormTree: null,
      isSubmitted: false,

      stripeCheckoutSessionId: null,
      standardProduct: { id: null, amount: 0 },
      premiumProduct: { id: null, amount: 0 },
      coachingProduct: { id: null, amount: 0 },
    };
    this.onLicenseTypeChanged = this.onLicenseTypeChanged.bind(this);
    this.onPlanFinalized = this.onPlanFinalized.bind(this);
    this.onPlanUnfinalized = this.onPlanUnfinalized.bind(this);
    this.mergeInvoiceForm = this.mergeInvoiceForm.bind(this);
    this.mergePlanForm = this.mergePlanForm.bind(this);
    this.validateInvoiceForm = this.validateInvoiceForm.bind(this);
    this.validatePlanForm = this.validatePlanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.navigate = this.navigate.bind(this);
  }
  navigate(stage) {
    console.log(`[CompanyOnboarding2] Navigating to stage...`, { stage });
    this.setState({ stage });

    if (stage === 'planSelect') {
      setTimeout(() => {
        // console.log('scrolling to #planSelectPage...');
        document.getElementById('planSelectPage').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Select Plan');
    }

    else if (stage === 'planConfirm') {
      setTimeout(() => {
        // console.log('scrolling to #planConfirmPage...');
        document.getElementById('planConfirmPage').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Confirm Plan');
    }

    else if (stage === 'invoice') {
      setTimeout(() => {
        // console.log('scrolling to #companyOnboardingInvoiceForm...');
        document.getElementById('companyOnboardingInvoiceForm').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Invoice');
    }

    else if (stage === 'wireInvoiceConfirm') {
      setTimeout(() => {
        // console.log('scrolling to #wireInvoiceConfirm...');
        document.getElementById('wireInvoiceConfirm').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics 
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Success Wire Transfer'); 
    }

  }
  onLicenseTypeChanged(licenseType) { // TODO deprecate
    // console.log('changed license type?', licenseType);
    this.merge({ licenseType });
  }
  onPlanFinalized() { // TODO deprecate
    this.setState({ stage: 'planConfirm' });
    // scroll to top
    // window.scrollTo(0, 0);
  }
  onPlanUnfinalized() { // TODO deprecate
    this.setState({ stage: 'planSelect' });
    // scroll to top
    // window.scrollTo(0, 0);
  }
  mergeInvoiceForm(newData) {
    let invoiceForm = shallowCopy(this.state.invoiceForm, newData);
    // invoiceForm.validate = true;
    this.setState({
      invoiceForm,
      invoiceFormTree: this.validateInvoiceForm(null, invoiceForm)
    });
  }
  mergePlanForm(newData) {
    let planForm = shallowCopy(this.state.planForm, newData);
    planForm.validate = true;
    let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, this.state.standardProduct, this.state.premiumProduct, this.state.coachingProduct);
    this.setState({
      planForm,
      planFormTree: this.validatePlanForm(null, planForm),
      total,
    });
  }
  validateInvoiceForm(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.invoiceForm;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.invoiceForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.invoiceFormTree && this.state.invoiceFormTree[attribute] && this.state.invoiceFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let licenseTypeValidation = validation.validateInt(data.licenseType) ? null : 'Company plan selection is required.'

      let parsedSeats = parseInt(data.seats);
      let seats = null;

      if (data.licenseType === enums.CompanyOnboarding.LicenseType.PMFoundations) {
        if (isNaN(parsedSeats) || parsedSeats < 1 || parsedSeats > 500) {
          seats = 'Invalid number of seats.';
        }
      }
      else if (data.licenseType === enums.CompanyOnboarding.LicenseType.Premium) {
        if (isNaN(parsedSeats) || parsedSeats < 1 || parsedSeats > 500) {
          seats = 'Invalid number of seats.';
        }
      }

      let tree = {
        name: validation.validateString(data.name) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'E-mail address is required.',
        companyName: validation.validateString(data.companyName) ? null : 'Company name is required.',
        companyAddress: validation.validateString(data.companyAddress) ? null : 'Company address is required.',
        seats: seats,
        licenseType: licenseTypeValidation,
      };
      return tree;
    }
  }
  validatePlanForm(attribute, newData) {
    let data = newData ? newData : this.state.planForm;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.planForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.planFormTree && this.state.planFormTree[attribute] && this.state.planFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        // console.log('will not validate this yet...');
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        seats: null,
      };

      let seats = parseInt(data.seats);
      if (isNaN(seats) || seats < 1 || seats > 500) {
        tree.seats = 'Invalid number of seats.';
      }
      // console.log('we have calculated planForm validation tree.', tree);

      return tree;
    }
  }
  async componentDidMount() {
    try {
      // console.log('[CompanyOnboarding] Handling componentDidMount()...');
      // fetch Products
      let products = await getProductsTeams();
      // console.log('Fetched products in componentDidMount()', products);
      let standardProduct = { id: null, amount: 0 };
      let premiumProduct = { id: null, amount: 0 };
      let coachingProduct = { id: null, amount: 0 };

      for (let p of products) {
        if (p.type === enums.CompanyProduct.Type.CompanySeat) {
          if (p.licenseType === enums.CompanyOnboarding.LicenseType.PMFoundations) {
            standardProduct = p;
            // console.log('fetched standard product.', standardProduct);
          }
          else if (p.licenseType === enums.CompanyOnboarding.LicenseType.Premium) {
            premiumProduct = p;
            // console.log('fetched premium product.', premiumProduct);
          }
        }
        else if (p.type === enums.CompanyProduct.Type.CoachingPackage) {
          coachingProduct = p;
          // console.log('fetched coaching package product.', coachingProduct);
        }
      }

      let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, standardProduct, premiumProduct, coachingProduct);

      // console.log('what are we setting in componentDidMount()?', { isLoading: false, products, standardPrice, premiumPrice, coachingPrice });
      this.setState({
        // isLoading: false,
        stage: 'planSelect',
        products,
        standardProduct,
        premiumProduct,
        coachingProduct,
        total,
      });
    } catch(e) {
      console.error('[CompanyOnboarding] Error handling componentDidMount().', e);
    }
  }
  async submit() { 
    try {
      console.log('submit() hit.');

      // ev.preventDefault();
      let invoiceForm = this.state.invoiceForm;
      let planForm = this.state.planForm;
      invoiceForm.validate = true;

      // attempt validation
      let invoiceFormTree = this.validateInvoiceForm(null, invoiceForm);
      console.log('we have a validation tree', invoiceFormTree);
      let isHealthy = validation.isTreeHealthy(invoiceFormTree);
      invoiceForm.isHealthy = isHealthy;

      this.setState({
        invoiceForm,
        invoiceFormTree,
        validate: true,
      });

      if (isHealthy) {
        let data = {
          name: invoiceForm.name,
          email: invoiceForm.email,
          companyName: invoiceForm.companyName,
          companyAddress: invoiceForm.companyAddress,
          companyVAT: invoiceForm.companyVAT,
          paymentMethod: parseInt(invoiceForm.paymentMethod),

          licenseType: planForm.licenseType,
          seats: parseInt(planForm.seats),
          coachingPackages: planForm.coachingPackages,

        };
        // console.log('we have a valid form.', data);
        // this.setState({ isLoading: true });
        // if (window) {
        //   // window.scrollTo(0, 0);
        // }

        // console.log('we have something to send', data);

        // set to 'submitted' so we can disable the submit button
        this.setState({
          isSubmitted: true,
        });

        let result = await submitCompanyOnboarding2(data);
        console.log('done with submission, got response from server.', result);

        // if result was stripe flow session
        if (result.stripeCheckoutSessionId) {
          this.setState({
            stripeCheckoutSessionId: result.stripeCheckoutSessionId,
          });
          return;
        }
        // if result is a wire transfer invoice confirmation
        else if (result.companyInvoiceIdString) {
          // this.setState({});
          // console.log('we generated invoice in backend, congratulations');


          // INPROG send this event to google analytics
          console.log('we should send success event to google analytics from here.');
          let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, this.state.standardProduct, this.state.premiumProduct, this.state.coachingProduct);
          console.log('calculated total.', total);
          await emitTeamsWireInvoiceGenerated(total.total, this.state.invoiceForm.email, this.state.planForm.licenseType, parseInt(this.state.planForm.seats));

          this.navigate('wireInvoiceConfirm');
          return;
        }

        // weird state - no idea what's going on
        this.setState({
          isLoading: false,
        });

      } else {
        console.log('Invalid form.', { invoiceForm, invoiceFormTree });
        //TODO display error message?
      }
    } catch(e) {
      console.error(`Error handling onSubmit().`, e);
    }
  }

  render() {


    // did we get stripeCheckoutSessionId?
    if (this.state.stripeCheckoutSessionId) {
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.state.stripeCheckoutSessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result.error) {
          console.error('Error inside Stripe Checkout Session', result.error.message);
          console.log(result);
        }
      });
    }

    if (this.state.stage === 'loading') {
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <LoadingCenter />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'planSelect') {
      return (
        <div>
          {/* <NewPurchaseFlowCloud /> */}
          <div className='pi2container'>
            <div className='pi2content'>
              <div className='newPurchaseTeamsPricing' id='newPurchaseTeamsPricing'>
                <PlanSelectPage
                  standardProduct={this.state.standardProduct}
                  premiumProduct={this.state.premiumProduct}
                  coachingProduct={this.state.coachingProduct}
                  planForm={this.state.planForm}
                  planFormTree={this.state.planFormTree}
                  mergePlanForm={this.mergePlanForm}
                  total={this.state.total}
                  isHealthy={validation.isTreeHealthy(this.state.planFormTree)}
                  navigate={this.navigate}
                  validate={this.validatePlanForm}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'planConfirm') {
      return (
        <PlanConfirmPage navigate={this.navigate} total={this.state.total} />
      );
    }

    else if (this.state.stage === 'invoice') {
      return (
        <InvoiceForm
          validate={this.validateInvoiceForm}
          form={this.state.invoiceForm}
          merge={this.mergeInvoiceForm}
          total={this.state.total}
          // navigate={this.navigate}
          submit={this.submit}
          isSubmitted={this.state.isSubmitted}
        />
      );
    }

    else if (this.state.stage === 'wireInvoiceConfirm') {
      // <CompanyOnboarding2ThankYouWire email={this.state.form.email} />
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <div className='wireInvoiceConfirm' id='wireInvoiceConfirm'>
                <CompanyOnboarding2ThankYouWire
                  email={this.state.invoiceForm.email}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    // are we done with stripe checkout flow?
    let status = getQueryAttribute(window.location.search, 's');
    if (status === 's') {
      //console.log('looks like we are done?');
      let email = getQueryAttribute(window.location.search, 'e');
      // console.log('passed e-mail?', email);
      let invoiceId = getQueryAttribute(window.location.search, 'i');
      // console.log('passed invoice identifier?', invoiceId)
      return <CompanyOnboardingThankYouStripe email={email} invoiceId={invoiceId} />;
    }

    // onboarding form / plan selection or billing information

  }
}


export default CompanyOnboarding2;


// <PaymentMethodSelect value={this.state.form.paymentMethod} onChange={paymentMethod => this.merge({ paymentMethod })}/>
class PaymentMethodSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    let message = '';
    if (this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe) {
      // message = 'We will take you to a credit card page to download your invoice and complete your payment.';
      message = 'Enter your credit card information next';
    }
    else if (this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer) {
      // message = 'We will include wire transfer details on the invoice generated on the next page.';
      message = 'Generate an invoice and see details on how to complete wire transfer';
    }

    return (
      <div className="formRow">
        <div className='paymentMethodSelect'>
          <div className='left'>
            <div
              className={this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe ? 'item active' : 'item'}
              onClick={() => this.props.onChange(enums.CompanyOnboarding.PaymentMethod.Stripe)} >
              <div className='lefty'>
                <div className={this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe ? 'radioButton active' : 'radioButton'} />
              </div>
              <div className='righty'>
                <label>Credit card</label>
              </div>
            </div>
            <div
              className={this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer ? 'item active' : 'item'}
              onClick={() => this.props.onChange(enums.CompanyOnboarding.PaymentMethod.WireTransfer)} >
              <div className='lefty'>
                <div className={this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer ? 'radioButton active' : 'radioButton'} />
              </div>
              <div className='righty'>
                <label>Wire transfer</label>
              </div>
            </div>
          </div>
  
          <div className='right'>
            <p>{message}</p>
          </div>
          
        </div>
      </div>
    );
  }
}


// <LicenseTypeSelect value={this.state.form.licenseType} onChange={this.onLicenseTypeChanged} />
class LicenseTypeSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='licenseTypeSelect2'>
        <div
          className={this.props.value === enums.CompanyOnboarding.LicenseType.PMFoundations ? 'item active' : 'item'}
          onClick={() => this.props.onChange({ licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations })} >
          <h3>{this.props.standardProduct.name}</h3>
          <h4>{toPrettyDollars(this.props.standardProduct.price.amount)}</h4>
          <p>
            Sign up your team for our Product Management Foundations course.
          </p>
          <button className='pi4button'>SELECT THIS PLAN</button>
        </div>
        
        <div
          className={this.props.value === enums.CompanyOnboarding.LicenseType.Premium ? 'item active' : 'item'}
          onClick={() => this.props.onChange({ licenseType: enums.CompanyOnboarding.LicenseType.Premium })} >
          <h3>{this.props.premiumProduct.name}</h3>
          <h4>{toPrettyDollars(this.props.premiumProduct.price.amount)}</h4>
          <p>
            Sign up your team for our Product Management Foundations course + all our additional courses.
          </p>
          <button className='pi4button'>SELECT THIS PLAN</button>
        </div>

        <div className='item'>
          <h3>Unlimited Licenses</h3>
          <h5>Contact Us</h5>
          <p>
            All our released courses to date, and unlimited licenses for you and your team.
          </p>
          <a href='mailto:info@productinstitute.com'>
            <button className='pi4button'>CONTACT SALES</button>
          </a>
        </div>

      </div>
    );
  }
}

// <CompanyOnboardingThankYouStripe email={email} />
class CompanyOnboardingThankYouStripe extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main className="thanks">
        {/* <div className="logo">
          <img src="https://pi2-web.s3.amazonaws.com/img/productinstitute-logo-color.svg" alt="Product Institute" />
        </div> */}
        <img src="https://pi2-web.s3.amazonaws.com/img/Group%209.png" className="thanks--bkg-img-1" alt="" />
        {/* <img src="https://pi2-web.s3.amazonaws.com/img/Group%2010.png" className="thanks--bkg-img-2" alt="" /> */}
        <div className="thanks--text-holder contactUsThanks">
          <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
          <h1>Thank you!</h1>
          <h2 className='left'>An invoice has been emailed to <a href={this.props.email}>{this.props.email}</a>.</h2>
          <p className='left'>
            Look out for onboarding instruction sent to your email within 48 hours.
            <br />
            <br />
            We’re so glad you've chosen Product Institute!
          </p>
        </div>
      </main>
    );
  }
}


function getTotal(licenseType, seats, coachingPackages, standardProduct, premiumProduct, coachingProduct) {

  let total = 0;
  let totalWithoutDiscount = 0;
  let discount = 0;
  let discountLabels = [];

  let items = [];

  let seatProduct = (licenseType === enums.CompanyOnboarding.LicenseType.Premium) ? premiumProduct : standardProduct;

  if (seats > 0) {
    let seatAmount = seats * seatProduct.price.amount;

    // INPROG disable discount calculator for now
    let discountPercent = 0;
    // if (seats > 20 && seats <= 50) { 
    //   discountPercent = 10;
    //   discountLabels.push('seats10');
    // }
    // else if (seats > 50) { 
    //   discountPercent = 15;
    //   discountLabels.push('seats15');
    // }

    let discountAmount = seatAmount * discountPercent / 100;
    let amount = seatAmount - discountAmount;

    total += seatAmount - discountAmount;
    totalWithoutDiscount += seatAmount;
    discount += discountAmount;

    items.push({
      name: seatProduct.name,
      description: seatProduct.description,
      type: enums.CompanyProduct.Type.CompanySeat,
      price: seatProduct.price.amount,
      quantity: seats,
      total: amount,
      totalWithoutDiscount: seatAmount,
      discount: discountAmount,
      discountPercent,
      thumbnail: seatProduct.thumbnail,
    });
  }

  if (coachingPackages > 0) {
    let coachingTotal = coachingProduct.price.amount * coachingPackages;
    total += coachingTotal;
    totalWithoutDiscount += coachingTotal;
    items.push({
      name: coachingProduct.name,
      description: coachingProduct.description,
      type: enums.CompanyProduct.Type.CoachingPackage,
      price: coachingProduct.price.amount,
      quantity: coachingPackages,
      total: coachingTotal,
      totalWithoutDiscount: coachingTotal,
      discount: 0,
      discountPercent: 0,
      thumbnail: coachingProduct.thumbnail,
    });
  }

  // console.log('we calculated total.', {
  //   items,
  //   total,
  //   totalWithoutDiscount,
  //   discount,
  //   discountLabels,
  // });

  return {
    items,
    total,
    totalWithoutDiscount,
    discount,
    discountLabels,
  };
}

// <TotalSummary total={} navigate={this.props.navigate} />
class TotalSummary extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    // let total = getTotal(this.props.form.licenseType, this.props.form.seats, this.props.form.coachingPackages, this.props.standardProduct, this.props.premiumProduct, this.props.coachingProduct);
    let total = this.props.total;

    let title = 'Total (1 Selected Item)';
    if (total.items.length === 2) {
      title = 'Total (2 Selected Items)';
    }

    let segment = '';
    if (total.discount > 0) {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            <span className='couseSelectTotalAmountBeforeDiscount'>
              {toPrettyDollarsRound(total.totalWithoutDiscount)}
            </span>
            &nbsp;
            <span>{toPrettyDollarsRound(total.total)}</span>
          </span>
          <p className='courseSelectTotalDiscountText'>
            You'll save {toPrettyDollarsRound(total.discount)} on your purchase with our bulk discount!
          </p>
        </div>
      );
    }
    else {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            {toPrettyDollarsRound(total.total)}
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className='courseSelectTotalContainer'>
          <div className='courseSelectTotal'>
            <div className='courseSelectTotalLeft'>
              <h2>{title}</h2>
              {segment}
            </div>
            <div className='courseSelectTotalRight'>
              <div>
                <button
                  className='pi4button pi4buttonLarge'
                  onClick={() => this.props.navigate('planConfirm')}
                >Continue to Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

// <PlanSelectPage
//   form={this.state.form}
//   onLicenseTypeChanged={this.onLicenseTypeChanged}
//   standardProduct={}
//   premiumProduct={}
//   coachingProduct={}
//   merge={this.merge}
//   onPlanFinalized={this.onPlanFinalized}
// />
class PlanSelectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMessage: '',
      isValid: true,
    };
    this.onSeatsChanged = this.onSeatsChanged.bind(this);
    this.onLicenseTypeChanged = this.onLicenseTypeChanged.bind(this);
  }
  onSeatsChanged(e) {
    let seats = e.target.value;
    this.props.merge({ seats });
    this.validate(this.props.form.licenseType, seats)
  }
  onLicenseTypeChanged(licenseType) {
    this.props.onLicenseTypeChanged(licenseType);
    this.validate(licenseType, this.props.form.seats);
  }

  render() {
    return (
      <section className='planSelectPage' id='planSelectPage'>

        <h2>Choose Your Team Plan</h2>

        <p>
          Ready to get started on your product management journey?
          To begin, choose a plan that best suits your team.
        </p>

        <br />

        <div className='pi2form'>

          <div className="formRow">
            <LicenseTypeSelect
              value={this.props.planForm.licenseType}
              onChange={this.props.mergePlanForm}
              standardProduct={this.props.standardProduct}
              premiumProduct={this.props.premiumProduct} />
          </div>

          <br />

          <div className="formRow numberOfStudents2">
            <div className='left'>
              <label htmlFor="input-company-seats">Enter Number of Students Enrolling</label>
              <p>
                If you're looking for unlimited seats or a dedicated course experience, <a href='mailto:info@productinstitute.com'>contact sales</a>.
              </p>
            </div>
            <div className='right'>
              <input
                className={` ${this.props.validate('seats')}`}
                type="number" pattern="[0-9]*" min="1" max="500"
                value={this.props.planForm.seats}
                onChange={ev => this.props.mergePlanForm({ seats: ev.target.value })}
              ></input>
            </div>
          </div>

          <br />

          {/* <div className="formRow teamDiscountBox">
            <div className='left'>
              <img src='https://pi2-web.s3.amazonaws.com/img/teams_discount.png' alt='Discounts' />
            </div>
            <div className='right'>
              <h3>
                Team Discounts
              </h3>
              <p>
                Psst... we offer bulk discounting for signing up more students! If you sign up more than <strong>20 students</strong>, we’ll give you <strong>  10% Off</strong>. If you sign up more than <strong>50</strong>, we’ll give you <strong>15% Off</strong>.
              </p>
            </div>
          </div> */}

          {/* <img src='https://pi2-web.s3.amazonaws.com/img/teams_all_courses.png' alt='PM Foundations + All Courses' />
          <img src='https://pi2-web.s3.amazonaws.com/img/teams_pmf.png' alt='PM Foundations' />
          <img src='https://pi2-web.s3.amazonaws.com/img/teams_virtual_coaching.png' alt='Virtual Coaching' /> */}

          <div className="formRow teamDiscountDisclaimerBox">
            * Licenses are non-transferable. We will grant each company the ability to re-assign up to 10% of their total licenses purchased within a year if the current enrolled employee leaves the company.
          </div>
          
          <div className='formRow teamVirtualCoaching'>

            <div className='linebreak'></div>

            <h3>Interested in Virtual Coaching?</h3>

            <p>
              Teams will attend five 90 minute discussion sessions via Zoom led by our experienced Product coaches as they work through or PM Foundations Course. They’ll receive peer and instructor critiques, ask questions Lean Coffee style, and dive deeper into how learned concepts and tools apply directly to their current work. Up to 15 students per package.
              <br />
              Contact <a href="mailto:info@productinstitute.com">info@productinstitute.com</a> to inquire and add a package after checkout.
            </p>

            {/* <br /> */}
            {/* <br /> */}

            {/* <div className="formRow numberOfCoachingPackages">
              <div className='left'>
                <label htmlFor="input-company-seats">Coaching Packages</label>
                <p>
                  Need coaching for more than 75 students? <a href='mailto:info@productinstitute.com'>Contact sales</a> for 6+ packages.
                </p>
              </div>
              <div className='right'>
                <select
                  className='pi2select'
                  value={this.props.planForm.coachingPackages}
                  onChange={ev => this.props.mergePlanForm({ coachingPackages: parseInt(ev.target.value) })}
                  placeholder='Select package'>
                  <option value='0'>No coaching</option>
                  <option value='1'>1 Package - 15 students ($15,000)</option>
                  <option value='2'>2 Packages - 30 students ($30,000)</option>
                  <option value='3'>3 Packages - 45 students ($45,000)</option>
                  <option value='4'>4 Packages - 60 students ($60,000)</option>
                  <option value='5'>5 Packages - 75 students ($75,000)</option>
                </select>
              </div>
            </div>
             */}
             
          </div>

          {
            this.props.isHealthy ?
              <TotalSummary
                // form={this.props.form}
                // standardProduct={this.props.standardProduct}
                // premiumProduct={this.props.premiumProduct}
                // coachingProduct={this.props.coachingProduct}
                total={this.props.total}
                navigate={this.props.navigate}
              /> : ''
          } 

        </div>

        <br />
      </section>
    );
  }
}

// <PlanConfirmPage navigate={this.navigate} />
class PlanConfirmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {

    let courses = [];
    for (let item of this.props.total.items) {

      let priceSegment = '';
      if (item.discount > 0) {
        priceSegment = (
          <h5>
            Price:&nbsp;
            <i>{toPrettyDollars(item.totalWithoutDiscount)}</i>
            &nbsp;
            <strong>{toPrettyDollars(item.total)}</strong>
          </h5>
        );
      }
      else {
        priceSegment = (
          <h5>
            Price:&nbsp;
            <strong>{toPrettyDollars(item.total)}</strong>
          </h5>
        );
      }

      if (item.type === enums.CompanyProduct.Type.CompanySeat) {
        let studentCountLabel = '';
        if (item.quantity === 1 || item.quantity === '1') {
          studentCountLabel = `(1 Student)`;
        } else {
          studentCountLabel = `(${item.quantity} Students)`;
        }
        let discountLabel = '';
        if (item.discountPercent === 10) {
          discountLabel = (
            <span className="courseSelectConfirmTotalDiscount">10% Off</span>
          );
        }
        else if (item.discountPercent === 15) {
          discountLabel = (
            <span className="courseSelectConfirmTotalDiscount">15% Off</span>
          );
        }

        courses.push(
          <div key={item.name} className='courseSelectConfirmItem'>
            <div className='courseSelectConfirmItemLeft'>
              <img src={item.thumbnail} alt={item.name} />
            </div>
            <div className='courseSelectConfirmItemRight'>
              <h4>{item.name} {studentCountLabel} {discountLabel}</h4>
              {priceSegment}
              {/* <a className='actionLink' onClick={() => this.props.cart.remove(course)}>Remove</a> */}
            </div>
          </div>
        );
      }
      else if (item.type === enums.CompanyProduct.Type.CoachingPackage) {
        let studentCountLabel = '';
        if (item.quantity === 1) {
          studentCountLabel = `(1 Package - 15 Students)`;
        } else {
          studentCountLabel = `(${item.quantity} Packages - ${item.quantity * 15} Students)`;
        }
        courses.push(
          <div key={item.name} className='courseSelectConfirmItem'>
            <div className='courseSelectConfirmItemLeft'>
              <img src={item.thumbnail} alt={item.name} />
            </div>
            <div className='courseSelectConfirmItemRight'>
              <h4>{item.name} {studentCountLabel}</h4>
              {priceSegment}
            </div>
          </div>
        );
      }
    }

    return (
      <div className='planConfirmPageContainer'>
        <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
        <div className='pi2container'>
          <div className='pi2content'>
            <div className='planConfirmPage' id='planConfirmPage'>
              <h2>You’re on your way to product excellence.</h2>
              <h3>Your Courses</h3>
              {courses}
              <div className='courseSelectReturn'>
                Want to change your plan or number of seats? <a className='actionLink' onClick={() => this.props.navigate('planSelect')}>Return to Course Selection</a>
              </div>
            </div>
            <PlanConfirmTotal total={this.props.total} />

            <div className='planConfirmFooter'>
              <button className='pi4button pi4buttonLarge' onClick={() => this.props.navigate('invoice')}>Continue</button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

// <PlanConfirmTotal total={total} />
class PlanConfirmTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total = this.props.total;
    let items = this.props.total.items;

    // count selected items
    let orderSummary = '';
    if (items.length > 0) {
      if (items.length === 1) {
        orderSummary = `1 Item`;
      }
      else {
        orderSummary = `${items.length} Items`;
      }
    }

    // add discount labels if discounts applied
    let discount = '';
    let discountLabels = [];
    if (total.discountLabels.length > 0) {
      for (let label of total.discountLabels) {
        if (label === 'seats10') {
          discountLabels.push(
            <span key='seats10' className='courseSelectConfirmTotalDiscount'>10% Off Bulk Discount (21+ students)</span>
          );
        }
        else if (label === 'seats15') {
          discountLabels.push(
            <span key='seats15' className='courseSelectConfirmTotalDiscount'>15% Off Bulk Discount (51+ students)</span>
          );
        }
        else {
          console.error('Unknown discount label.', label);
        }
      }
    }
    if (total.discount > 0) {
      discount = (
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Discount&nbsp;
            {discountLabels}
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            -{toPrettyDollars(total.discount)}
          </div>
        </div>
      );
    }

    return (
      <div className='courseSelectConfirmTotal'>
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            <strong>Order Summary</strong>
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            <strong>{orderSummary}</strong>
          </div>
        </div>

        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Subtotal
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollars(total.totalWithoutDiscount)}
          </div>
        </div>

        {discount}

        <div className='courseSelectConfirmTotalRow courseSelectConfirmTotalTotal'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Your Total
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollars(total.total)}
          </div>
        </div>
      </div>
    )
  }
}

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    let validate = this.props.validate;
    let form = this.props.form;
    let merge = this.props.merge;


    let continueButton = '';

    if (this.props.isSubmitted) {
      continueButton = (
        <button disabled='disabled' className='pi4button pi4buttonLarge'>Submitting...</button>
      );
    }
    else {
      continueButton = (
        <button className='pi4button pi4buttonLarge' onClick={this.props.submit}>Continue</button>
      );
    }

    return (

      <div className='companyOnboardingInvoiceFormContainer'>
        <img className='macka' src='https://pi2-web.s3.amazonaws.com/img/macka.png' alt='Product Institute' />
        <div className='pi2container'>
          <div className='pi2content'>

            <section className='companyOnboarding2' id='companyOnboardingInvoiceForm'>
              <div className='spacer'></div>
              <div className='content'>
                <h2>Invoice Information</h2>
                <p>
                  Fill out the information below and we’ll generate an invoice for you.
                  {/* <br />
                  Don’t need one after all? You can skip this step. */}
                </p>
                <br />
                <div className='pi2form'>
                  <div className="formRow">
                    <label htmlFor="input-fullname">Invoice made out to</label>
                    <input type='text' className={` ${validate('name')}`} id='input-fullname' name='name' value={form.name} onChange={e => merge({ name: e.target.value })} placeholder='e.g. John Smith' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-company-name">Company name</label>
                    <input type='text' className={` ${validate('companyName')}`} id='input-company-name' name='companyName' value={form.companyName} onChange={e => merge({ companyName: e.target.value })} placeholder='e.g. Acme' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-message">Company address</label>
                    <textarea className={` ${validate('companyAddress')}`} id='company-address' name='company-address' value={form.companyAddress} onChange={e => merge({ companyAddress: e.target.value })} placeholder="e.g. &#x000D;&#x000A;123 West Court &#x000D;&#x000A;Westbury, NY 11590 &#x000D;&#x000A;United States" style={{ minHeight: '120px' }}/>
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-email">Email</label>
                    <input type='email' className={` ${validate('email')}`} id='input-email' name='email' value={form.email} onChange={e => merge({ email: e.target.value })} placeholder='e.g. jsmith@company.com' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-company-vat">VAT number (if applicable)</label>
                    <input type='text' className='' id='input-company-vat' name='input-vat' value={form.companyVAT} onChange={e => merge({ companyVAT: e.target.value })} placeholder='VAT #' />
                  </div>
                  <br />
                  <PlanConfirmTotal total={this.props.total} />
                  <br />
                  <h5>Payment Method</h5>
                  <br />
                  <PaymentMethodSelect value={form.paymentMethod} onChange={paymentMethod => merge({ paymentMethod })} />
                  <br />
                  <br />

                  <div className='linebreak'></div>
                  <br />
                  {/* <p className='legal'>
                    By subscribing for the number of Library Licenses as indicated above, I agree to be bound by the terms of all of the documents and/or policies set forth below, all of which are incorporated together to form a single legally binding agreement between Client and the Product Institute (this single legal agreement is collectively referred to as the "Click-Through Agreement" (the documents below, other than this Invoice which is generated and emailed to Client, can all be found at);
                  </p>
                  <ol className='legal'>
                    <li>the Invoice,</li>
                    <li>the <a href='https://productinstitute.com/p/click-through'>Click-Through License Terms</a></li>
                    <li>the <a href='https://productinstitute.com/p/glossary'>Glossary</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/terms'>End-User Terms &amp; Conditions</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/programpolicies'>Program Policies</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/sla'>SLA</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/privacy'>Privacy Policy</a>.</li>
                  </ol> */}
                  <p className='legal'>
                    By purchasing a “Course,” you shall become a “Client” of the Product Institute and by doing so, shall be bound by the terms of all of the following documents or policies, which are incorporated together to form a single legal agreement that will be binding on you as the Client and the Product Institute (this single legal agreement is collectively referred to as the “Click-Through Agreement”):
                  </p>
                  <ol className='legal'>
                    <li>the Invoice (which is generated upon ordering the Library License(s),</li>
                    <li>the <a href='https://productinstitute.com/p/click-through'>PI Terms</a></li>
                    <li>the <a href='https://productinstitute.com/p/glossary'>Glossary</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/terms'>End-User Terms &amp; Conditions</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/programpolicies'>Program Policies</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/sla'>SLA</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/privacy'>Privacy Policy</a>.</li>
                  </ol>
                  <p className='legal'>
                    The above documents (other than the Invoice which is generated and emailed to Client) can all be found at the following URL: <a href='https://productinstitute.com/p/click-through'>https://productinstitute.com/p/click-through</a>.
                    <br />
                    <br />
                    By purchasing one or more Courses, you on behalf of yourself, or on behalf of your principal if you are acting on behalf of an entity (as listed on the Invoice as a Client), are hereby indicating that you have clicked on the hyperlink associated with each of these above documents or have otherwise accessed each of these particular documents, have reviewed the terms of each of these particular documents, have the authority to enter into the Click-Through Agreement if you are acting on behalf of your principal (as listed on the Invoice) and hereby consent and obligate yourself or your principal (as listed on the Invoice) to all of the terms contained within each of these particular documents – some of which address the following issues: Client responsibilities, intellectual property rights, ownership of data, limitation of liability, limited warranty, indemnification, confidentiality, dispute resolution, service level requirements and branding requirements.
                  </p>
                  <div className="formRow buttonRow">
                    <br />
                    {continueButton}
                    <br />
                    <br />
                  </div>
                </div>

              </div>
              <div className='spacer'></div>
            </section>
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>

      </div>

    );
  }
}
