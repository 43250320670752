import React from 'react';

import { getQueryAttribute, shallowCopy, toPrettyDollarsRound } from './helpers';


import {
  getProducts,
  getCheckoutSession,
  validateCoupon,
  validateEmail,
  checkout, 
} from './ajax';


import validation from './validation';
import { emitPageView, emitIndividualPurchaseComplete, emitTeamsPurchaseComplete } from './analytics';
import enums from './enums';

import LoadingCenter from './LoadingCenter';
import { FormCheckbox } from './FormElements';
import FormYesNo from './FormYesNo';

import CompanyOnboarding2 from './CompanyOnboarding2';

import NewPurchaseFlowCloud from './NewPurchaseFlowCloud';
import NewPurchaseFlowSelect from './NewPurchaseFlowSelect';

// eslint-disable-next-line no-undef
const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__; 

var stripe = null;
if (typeof Stripe !== 'undefined') {
  // eslint-disable-next-line no-undef
  stripe = Stripe(STRIPE_PUBKEY);
} else {
  console.warn('[NewPurchaseFlow] Stripe library not loaded.');
}


/* individuals checkout */

class Cart {
  constructor(items) {
    // cart items
    this.items = items || [];
    // cart state change subscriptions
    this.subscribers = [];
    // calculate cart total
    this.total = this.calculateTotal();
  }
  // subscribe to state change
  subscribe(callback) {
    // console.log('[Cart] Subscribing to cart change...');
    this.subscribers.push(callback);
  }
  unsubscribe(callback) {
    // console.log('[Cart] Unsubscribing from cart change...');
    this.subscribers = this.subscribers.filter(cb => cb === callback ? false : true);
  }
  publish() {
    // console.log('[Cart] Publishing cart changes...');
    // publish new items & total
    this.subscribers.forEach(callback => callback({ items: this.items, total: this.total }));
  }
  add(item) {
    // console.log(`[Cart] Adding item ${item.id} to cart...`);
    let existing = this.items.find(i => i.id === item.id);
    if (!existing) {
      this.items.push(item);
      this.total = this.calculateTotal();
      // notify subscribers that items changed
      this.publish();
    }
  }
  replace(item) {
    let found = false;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id === item.id) {
        // console.log(`[Cart] Replacing item ${item.id} to cart...`);
        this.items[i] = item;
        this.total = this.calculateTotal();
        // notify subscribers that items & total changed
        this.publish();
        found = true;
      }
    }
    if (!found) {
      console.log(`[Cart] Discounted item ${item.id} not found in cart.`);
    }
    return found;
  }
  remove(item) {
    // console.log(`[Cart] Removing item ${item.id} from cart...`);
    let existing = this.items.find(i => i.id === item.id);
    if (existing) {
      let index = this.items.indexOf(existing);
      this.items.splice(index, 1);
      this.total = this.calculateTotal();
      // notify subscribers that items changed
      this.publish();
    }
  }
  isItemInCart(item) {
    // console.log(`[Cart] Checking if item ${item.id} is in cart...`);
    let existing = this.items.find(i => i.id === item.id);
    return existing ? true : false;
  }
  getCart() {
    return { items: this.items, total: this.total };
  }
  calculateTotal() {
    // calculate total price based on what's in the cart
    let total = 0;
    let totalWithoutDiscount = 0;
    let discount = 0;
    let discountCouponCode = '';

    // check 'foundations + deep dive' bundle/combo discount eligibility
    let isComboDiscount = false;
    let isAlmostComboDiscount = false;
    let isMainCourseAdded = false;
    let dessertCourseCount = 0;
    for (let item of this.items) {
      if (item.type === enums.IndividualProduct.Type.MainCourse) {
        isMainCourseAdded = true;
      }
      else if (item.type === enums.IndividualProduct.Type.Dessert) {
        dessertCourseCount++;
      }
    }
    if (isMainCourseAdded && dessertCourseCount === 1) {
      isAlmostComboDiscount = true;
    }
    else if (isMainCourseAdded && dessertCourseCount > 1) {
      isComboDiscount = true;
    }

    // if not bundle, check if there are any applied coupons
    let isCouponDiscount = false;
    if (!isComboDiscount) {
      for (let item of this.items) {
        // TODO check if coupon is applied
        if (item.isDiscounted) {
          // console.log('Item has a discount applied.', item);
          isCouponDiscount = true;
          discountCouponCode += item.priceDiscount.discountCouponCode;
        }
      }
    }


    // TODO add coupon discount detection (does any course have a coupon-based discount?)
    // - this should only be applicable if isComboDiscount is false, as we can't stack discounts

    // calculate total now that we know what's here
    // calculate totals - full price
    if (!isComboDiscount && !isCouponDiscount) {
      for (let item of this.items) {
        if (item.type === enums.IndividualProduct.Type.MainCourse) {
          total += item.price.amount;
          totalWithoutDiscount += item.price.amount;
        }
        else if (item.type === enums.IndividualProduct.Type.Dessert) {
          total += item.price.amount;
          totalWithoutDiscount += item.price.amount;
        }
      }
    }
    else if (isComboDiscount) {
      for (let item of this.items) {
        // // no discount on main course
        // if (item.type === enums.IndividualProduct.Type.MainCourse) {
        //   total += item.price.amount;
        //   totalWithoutDiscount += item.price.amount;
        // }
        // // if 2+ dessert courses, those are discounted
        // else if (item.type === enums.IndividualProduct.Type.Dessert) {
        //   total += item.priceComboOffer.amount;
        //   totalWithoutDiscount += item.price.amount;
        //   discount += item.price.amount - item.priceComboOffer.amount;
        // }
        // TODO add discount label

        // pi3 everything can have a combo discount
        if (item.priceComboOffer) {
          total += item.priceComboOffer.amount;
          totalWithoutDiscount += item.price.amount;
          discount += item.price.amount - item.priceComboOffer.amount;
        }
        else {
          total += item.price.amount;
          totalWithoutDiscount += item.price.amount;
        }

      }
    }
    else if (isCouponDiscount) {
      for (let item of this.items) {
        if (item.isDiscounted) {
          total += item.priceDiscount.amount; // INPROG
          totalWithoutDiscount += item.price.amount; // INPROG fixme
          discount += item.price.amount - item.priceDiscount.amount; // INPROG
        }
        else {
          total += item.price.amount;
          totalWithoutDiscount += item.price.amount;
        }
        // TODO add discount label
      }
    }


    return {
      items: this.items,
      total,
      totalWithoutDiscount,
      isAlmostComboDiscount,
      isComboDiscount,
      isCouponDiscount,
      discount,
      discountCouponCode,
    };
  }
}

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseFlow: 'loading', // null, 'individuals', 'teams', 'loading'
      status: null,
      mode: null,
      invoice: null,
    };
    this.changeFlow = this.changeFlow.bind(this);
  }

  async componentDidMount() {
    try {
      // parse query strings
      let status = getQueryAttribute(window.location.search, 's'); // s
      let mode = getQueryAttribute(window.location.search, 'm'); // c
      let invoice = getQueryAttribute(window.location.search, 'in');
      let stripeCheckoutSessionId = getQueryAttribute(window.location.search, 'csid');
      let emailUser = getQueryAttribute(window.location.search, 'e');
      this.setState({ status, mode, invoice, stripeCheckoutSessionId });

      console.log('[Pricing] Parsed query strings.', { status, mode, invoice, stripeCheckoutSessionId });

      // if checkout success individuals
      if (status === 's' && mode === 'i') {
        // send 'page view' event
        emitPageView('/checkout/individuals/success', 'Checkout Individuals - Success');
        // fetch checkout session
        let cs = await getCheckoutSession(stripeCheckoutSessionId);
        console.log('[Pricing] Fetched checkout session.', cs.session);
        let emailStripe = (cs.session && cs.session.customer_details && cs.session.customer_details.email) ? cs.session.customer_details.email : null;
        // emit 'individual stripe checkout complete' event (google analytics)
        emitIndividualPurchaseComplete(cs.session.amount_total, stripeCheckoutSessionId, emailUser, emailStripe);
      }

      // checkout success teams - stripe checkout completed
      else if (status === 's' && mode === 'c') {
        // send 'page view' event
        emitPageView('/checkout/teams/success-stripe', 'Checkout Teams - Success Stripe Checkout');
        // fetch checkout session
        let cs = await getCheckoutSession(stripeCheckoutSessionId);
        console.log('[Pricing] Fetched checkout session.', cs.session);

        let emailStripe = (cs.session.customer_details && cs.session.customer_details.email) ? cs.session.customer_details.email : null;
        let licenseType = (cs.session.metadata && cs.session.metadata.licenseType) ? parseInt(cs.session.metadata.licenseType) : null;
        let seats = (cs.session.metadata && cs.session.metadata.seats) ? parseInt(cs.session.metadata.seats) : null;
        // send 'teams stripe checkout complete' event
        emitTeamsPurchaseComplete(cs.session.amount_total, stripeCheckoutSessionId, emailUser, emailStripe, licenseType, seats); 
      }

      // not a success page, this is the default purchaseFlow
      else {
        this.setState({ purchaseFlow: null });
      }

    }
    catch(e) {
      console.error(`[Pricing] Error in componentDidMount()`, e);
    }
  }

  changeFlow(newPurchaseFlow) {
    console.log('[Pricing] Purchase flow changed.', { purchaseFlow: newPurchaseFlow });
    // TODO if changed from null to 'individuals', scroll down
    this.setState({ purchaseFlow: newPurchaseFlow });
  }
  render() {

    // purchase succeeded, redirecting back from Stripe Checkout
    if (this.state.status === 's') {
      // individuals
      if (this.state.mode === 'i') {

        let invoice = this.state.invoice === '1';

        return (
          <div>
            {/* <div className='pi2container'>
              <div className='pi2content'>
                <ThankYouIndividual />
              </div>
            </div> */}
            <ThankYouIndividual invoice={invoice} />
          </div>
        );
      }

      // company checkout
      else if (this.state.mode === 'c') {
        // google analytics
        // emitPageView('/checkout/teams/success-stripe', 'Checkout Teams - Success Stripe Checkout');
        return (
          <div>
            <CompanyOnboarding2ThankYouStripe />
          </div>
        );
      }
    }


    // default state, show cloud & individuals/teams selcetor
    if (this.state.purchaseFlow === null) {

      // google analytics
      emitPageView('/checkout', 'Checkout'); 

      return (
        <div>
          {/* <NewPurchaseFlowCloud /> */}
          <div className='pi2container'>
            <div className='pi2content'>
              <div className='newPurchasePricing'>
                <NewPurchaseFlowSelect flow={this.state.purchaseFlow} onChange={this.changeFlow} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    // show the individual purchase flow
    else if (this.state.purchaseFlow === 'individuals') {

      // google analytics
      emitPageView('/checkout/indviduals', 'Checkout Individuals');

      return (
        <div>
          <IndividualPurchaseFlow flow={this.state.purchaseFlow} onChange={this.changeFlow} />
        </div>
      );
    }

    // show the teams purchase flow
    else if (this.state.purchaseFlow === 'teams') {
      // google analytics
      emitPageView('/checkout/teams', 'Checkout Teams');
      return (
        <div>
          <CompanyOnboarding2 flow={this.state.purchaseFlow} onChange={this.changeFlow} />
        </div>
      );
    }

    else if (this.state.purchaseFlow === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    console.error('Pricing component landed in strange state.', { purchaseFlow: this.state.purchaseFlow });
    // this should not happen
    return '';

  }
}

class ThankYouIndividual extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    let mailLine = 'You will receive a confirmation email shortly.';
    if (this.props.invoice) {
      mailLine = (
        <span>
          You will receive a confirmation email with your invoice shortly.
        </span>
      );
    }

    return (
      <main className="newPurchaseFlowThanks">
        <img src="https://pi2-web.s3.amazonaws.com/img/Group%209.png" className="thanks--bkg-img-1" alt="" />
        <img src="https://pi2-web.s3.amazonaws.com/img/Group%2010.png" className="thanks--bkg-img-2" alt="" />
        <div className="thanks--text-holder contactUsThanks">
          <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
          <h1>Purchase Complete</h1>
          <br />
          <p>
            Thank you for choosing Product Institute!
            <br />
            {mailLine}
          </p>
        </div>
      </main>
    );
  }
}




class IndividualPurchaseFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // loading, courseSelect, couseSelectDiscount, courseSelectConfirm, signup, invoice, confirm...
      products: [],
      // initiate cart
      cart: new Cart(),
      // initiate user form
      userForm: {
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
        terms: false,
        marketing: false,
        invoice: true,
        validate: false,
      },
      // initiate user form validation tree
      userFormTree: null,
      // initiate invoice form
      invoiceForm: {
        name: '',
        email: '',
        companyName: '',
        companyVAT: '',
        companyAddress: '',
      },
      // initiate invoice form validation tree
      invoiceFormTree: null,

      couponForm: {
        stage: null, // null, submitting, (bundle), (coupon), invalid
        couponCode: '',
      },
    };
    this.navigate = this.navigate.bind(this);
    this.mergeUserForm = this.mergeUserForm.bind(this);
    this.validateUserForm = this.validateUserForm.bind(this);
    this.mergeInvoiceForm = this.mergeInvoiceForm.bind(this);
    this.validateInvoiceForm = this.validateInvoiceForm.bind(this);
    this.mergeCouponForm = this.mergeCouponForm.bind(this); 
    this.submitCouponForm = this.submitCouponForm.bind(this);
    this.submit = this.submit.bind(this);
  }
  navigate(stage) {
    console.log(`[IndividualPurchaseFlow] Navigating to stage...`, { stage });
    this.setState({ stage });

    if (stage === 'couseSelectDiscount') {
      // setTimeout(() => {
      //   // console.log('scrolling to #courseSelectDiscountPage...');
      //   document.getElementById('courseSelectDiscountPage').scrollIntoView({ behavior: 'smooth' });
      // }, 300);
      setTimeout(() => {document.getElementById('pi3checkout').scrollIntoView({ behaviour: 'smooth' });}, 300);
      // google analytics
      emitPageView(`/checkout/individuals/${stage}`, 'Checkout Individuals - Discount Offer');
    }

    else if (stage === 'courseSelectConfirm') {
      // setTimeout(() => {
      //   // console.log('scrolling to #courseSelectConfirmPage...');
      //   document.getElementById('courseSelectConfirmPage').scrollIntoView({ behavior: 'smooth' });
      // }, 300);
      setTimeout(() => {document.getElementById('pi3checkout').scrollIntoView({ behaviour: 'smooth' });}, 300);

      // google analytics
      emitPageView(`/checkout/individuals/${stage}`, 'Checkout Individuals - Confirm Selection');
    }

    else if (stage === 'signup') {
      // setTimeout(() => {
      //   // console.log('scrolling to #newPurchaseSignupForm...');
      //   document.getElementById('newPurchaseSignupForm').scrollIntoView({ behavior: 'smooth' });
      // }, 300);
      setTimeout(() => {document.getElementById('pi3checkout').scrollIntoView({ behaviour: 'smooth' });}, 300);

      // google analytics
      emitPageView(`/checkout/individuals/${stage}`, 'Checkout Individuals - Signup Form');
    }

    else if (stage === 'invoice') {
      // setTimeout(() => {
      //   // console.log('scrolling to #newPurchaseInvoiceForm...');
      //   document.getElementById('newPurchaseInvoiceForm').scrollIntoView({ behavior: 'smooth' });
      // }, 300);
      setTimeout(() => {document.getElementById('pi3checkout').scrollIntoView({ behaviour: 'smooth' });}, 300);

      // google analytics
      emitPageView(`/checkout/individuals/${stage}`, 'Checkout Individuals - Invoice Form');
    }

  }
  // <SignupForm form={this.state.userForm} validate={this.validateUserForm} onChange={} navigate={this.navigate} />
  mergeUserForm(newData) {
    let userForm = shallowCopy(this.state.userForm, newData);
    this.setState({
      userForm,
      userFormTree: this.validateUserForm(null, userForm)
    });
  }
  validateUserForm(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.userForm;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.userForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.userFormTree && this.state.userFormTree[attribute] && this.state.userFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        name: validation.validateString(data.name) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'E-mail address is required.',
        // password: validation.validatePassword(data.password, 8) ? null : 'Password is too short.',
        // repeatPassword: data.password === data.repeatPassword ? null : 'Passwords do not match',
      };
      return tree;
    }
  }
  // <InvoiceForm form={this.state.invoiceForm} validate={this.validateInvoiceForm} onChange={} navigate={this.navigate} />
  mergeInvoiceForm(newData) {
    let invoiceForm = shallowCopy(this.state.invoiceForm, newData);
    this.setState({
      invoiceForm,
      invoiceFormTree: this.validateInvoiceForm(null, invoiceForm)
    });
  }
  validateInvoiceForm(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.invoiceForm;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.invoiceForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.invoiceFormTree && this.state.invoiceFormTree[attribute] && this.state.invoiceFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        name: validation.validateString(data.name) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'Invalid email address.',
        companyName: validation.validateString(data.companyName) ? null : 'Company name is required.',
        companyAddress: validation.validateString(data.companyAddress) ? null : 'Company address is required.',
      };
      return tree;
    }
  }
  mergeCouponForm(newData) { 
    let couponForm = shallowCopy(this.state.couponForm, newData);
    this.setState({
      couponForm,
    });
  }
  async submitCouponForm() { 
    try {
      let couponForm = this.state.couponForm;
      console.log('[IndividualPurchaseFlow] We are submitting coupon form...', { couponForm });
      // INPROG set page to 'submitting'
      this.mergeCouponForm({ stage: 'submitting' });
      // TODO validate coupon code
      let validationResult = await validateCoupon(this.state.couponForm.couponCode);
      // console.log('Fetched results from coupon code validation...', { validationResult });
      // TODO if invalid, set couponForm.stage to invalid
      if (!validationResult.discounts || validationResult.discounts.length === 0) {
        // console.log('Validation failed.');
        this.mergeCouponForm({ stage: 'invalid' });
      }
      else {
        // console.log('Validation succeeded.');
        // INPROG replace items in cart with discounted items
        let isDiscountApplied = false;
        for (let discountedItem of validationResult.discounts) {
          // console.log('Adding discounted item to cart.', { discountedItem });
          let isDiscountAppliedHere = this.state.cart.replace(discountedItem);
          if (isDiscountAppliedHere) {
            // console.log('There is an item in the cart that is discounted by this coupon code');
            isDiscountApplied = true;
          }
        }

        if (isDiscountApplied) {
          this.mergeCouponForm({ stage: 'applied' });
        }
        else {
          this.mergeCouponForm({ stage: null, couponCode: '' });
        }


      }

      // TODO if valid, set couponForm.stage to coupon whatever
    }
    catch(e) {
      console.error('[IndividualPurchaseFlow] Error in submitCouponForm().', e);
    }
  }
  async submit() {
    try {
      // console.log('[IndividualPurchaseFlow] Submitting user onboarding...');

      let userForm = this.state.userForm;
      let invoiceForm = this.state.invoiceForm;

      let items = this.state.cart.items;
      let itemIds = items.map(i => i.id);

      let data = {
        name: userForm.name,
        email: userForm.email,
        // password: userForm.password,

        terms: userForm.terms,
        marketing: userForm.marketing,
        invoice: userForm.invoice,

        invoiceName: '',
        invoiceEmail: '',
        companyName: '',
        companyAddress: '',
        companyVAT: '',

        itemIds,

        couponCode: this.state.couponForm.couponCode,
      };

      if (userForm.invoice) {
        data.invoiceName = invoiceForm.name;
        data.invoiceEmail = invoiceForm.email;
        data.companyName = invoiceForm.companyName;
        data.companyAddress = invoiceForm.companyAddress;
        data.companyVAT = invoiceForm.companyVAT;
      }

      if (!data.terms) {
        // console.log('User did not agree to terms, cannot submit this.');
        return;
      }

      // console.log('are we ready for submission?', data);


      // INPROG set page to 'loading'
      this.setState({ stage: 'loading' });

      // INPROG submit onboarding data
      // let result = await submitIndividualOnboarding(data);
      let result = await checkout(data);

      // INPROG add received stripeCheckoutSessionId
      this.setState({
        stage: 'stripeCheckout',
        stripeCheckoutSessionId: result.stripeCheckoutSessionId 
      });


    }
    catch(e) {
      console.error('[IndividualPurchaseFlow] Error in submit().', e);
    }
  }

  async componentDidMount() {
    try {
      // console.log('[IndividualPurchaseFlow] Fetching individual products...');
      let ip = await getProducts();
      // console.log('[IndividualPurchaseFlow] Fetched individual products.', ip.products);
      this.setState({ products: ip.products, stage: 'courseSelect' });
    }
    catch(e) {
      console.error('[IndividualPurchaseFlow] Error in componentDidMount().', e);
    }
  }

  render() {

    if (this.state.stage === 'loading') {
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <LoadingCenter />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'courseSelect') {
      return (
        <div>
          {/* <NewPurchaseFlowCloud /> */}
          <div className='pi2container'>
            <div className='pi2content'>
              <div className='newPurchasePricing'>
                <CourseSelectPage products={this.state.products} cart={this.state.cart} navigate={this.navigate} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'couseSelectDiscount') {
      return (
        <div>
          <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
          <div className='pi2container'>
            <div className='pi2content'>
              <CourseSelectDiscount products={this.state.products} cart={this.state.cart} navigate={this.navigate} />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'courseSelectConfirm') {
      return (
        <div>
          <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
          <div className='pi2container'>
            <div className='pi2content'>
              <CourseSelectConfirm
                couponForm={this.state.couponForm} onCouponChange={this.mergeCouponForm} onCouponSubmit={this.submitCouponForm}
                products={this.state.products} cart={this.state.cart} navigate={this.navigate} 
              />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'signup') {
      return (
        <div>
          <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
          <div className='pi2container'>
            <div className='pi2content'>
              <SignupForm form={this.state.userForm} validate={this.validateUserForm} onChange={this.mergeUserForm} navigate={this.navigate} submit={this.submit} />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'invoice') {
      return (
        <div>
          <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
          <div className='pi2container'>
            <div className='pi2content'>
              <InvoiceForm
                form={this.state.invoiceForm}
                validate={this.validateInvoiceForm}
                onChange={this.mergeInvoiceForm}
                navigate={this.navigate}
                submit={this.submit}
              />
            </div>
          </div>
        </div>
      );
    }


    else if (this.state.stage === 'stripeCheckout') {

      stripe.redirectToCheckout({ 
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.state.stripeCheckoutSessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result.error) {
          console.error('Error inside Stripe Checkout Session', result.error);
        }
      });
      // return 'loading' for a brief period until redirectToCheckout() works
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <LoadingCenter />
            </div>
          </div>
        </div>
      );
    }

    console.error('[Pricing] Got into weird state.', this.state);
    return '';
  }
}

class CourseSelectMainCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let course = this.props.course;
    let cart = this.props.cart;

    let button = '';
    let isCourseAdded = cart.isItemInCart(course);
    if (isCourseAdded) {
      button = (
        <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(course)}>
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
          Added to cart
        </button>
      );
    }
    else {
      button = (
        <button className='pi4button pi2uppercase' onClick={() => cart.add(course)}
        >Add to cart</button>
      );
    }

    return (
      <div className='newPurchaseSelectMainCourse'>
        <div className='mainCourseIcon'>
          <img src={course.thumbnail} alt={course.name} />
        </div>
        <div className='mainCourseContent'>
          <h3>{course.name}</h3>
          <p>{course.description}</p>
          <p><a href={this.props.course.overview} target='_new'>View Curriculum</a></p>
        </div>
        <div className='mainCoursePrice'>
          <div className='mainCoursePriceAmount'>{toPrettyDollarsRound(course.price.amount)}</div>
          {button}
        </div>
      </div>
    );
  }
}

class CourseSelectDessertCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    let course = this.props.course;
    let cart = this.props.cart;

    let button = '';
    let isCourseAdded = cart.isItemInCart(course);
    if (isCourseAdded) {
      button = (
        <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(course)}
        >
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
          Added to cart
        </button>
      );
    }
    else {
      button = (
        <button className='pi4button pi2uppercase' onClick={() => cart.add(course)}
        >Add to cart</button>
      );
    }

    let newBadge = '';
    if (this.props.course.isNew) {
      newBadge = (
        <span className='pi2newBadge'>
          <img src='https://pi2-web.s3.amazonaws.com/img/new1.png' alt='NEW!' />
        </span>
      );
    }

    return (
      <div className='newPurchaseSelectDessertCourse'>
        {newBadge}
        <h3>{this.props.course.name}</h3>
        <img src={course.thumbnail} alt={course.name} />
        <p>{this.props.course.shortDescription}</p>
        <p><a href={this.props.course.overview} target='_new'>View Curriculum</a></p>
        {/* {toPrettyDollarsRound(this.props.course.price.amount)} */}
        <br />
        {button}
      </div>
    );
  }
}

class CourseSelectTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.continue = this.continue.bind(this);
  }
  continue() {
    // user added something to cart, continue down the purchase flow
    let isAlmostComboDiscount = this.props.total.isAlmostComboDiscount;
    this.props.navigate(isAlmostComboDiscount ? 'couseSelectDiscount' : 'courseSelectConfirm');
  }
  render() {

    // nothing in cart yet
    if (this.props.total.items && this.props.total.items.length === 0) {
      console.log('[CourseSelectTotal] No items in cart.');
      return '';
    }

    // count selected items
    let title = '';
    if (this.props.total.items && this.props.total.items.length > 0) {
      if (this.props.total.items.length === 1) {
        title = `Total (1 Selected Item)`;
      }
      else {
        title = `Total (${this.props.total.items.length} Selected Items)`;
      }
    }

    let segment = '';

    // check if discount is applied 
    if (this.props.total.isComboDiscount) {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            <span className='couseSelectTotalAmountBeforeDiscount'>
              {toPrettyDollarsRound(this.props.total.totalWithoutDiscount)}
            </span>
            &nbsp;
            <span>{toPrettyDollarsRound(this.props.total.total)}</span>
          </span>
          <p className='courseSelectTotalDiscountText'>
            You'll save {toPrettyDollarsRound(this.props.total.discount)} on your purchase with our special offer!
            {/* You'll save {toPrettyDollarsRound(this.props.total.discount)} on your purchase with our holiday sale! */}
          </p>
        </div>
      );
    }
    else {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            {toPrettyDollarsRound(this.props.total.total)}
          </span>
        </div>
      );
    }

    let cont = (
      <div>
        <button className='pi4button pi4buttonLarge' onClick={this.continue}>Continue to Checkout</button>
      </div>
    );

    return (
      <div className='courseSelectTotal'>
        <div className='courseSelectTotalLeft'>
          <h2>{title}</h2>
          {segment}
        </div>
        <div className='courseSelectTotalRight'>
          {cont}
        </div>
      </div>
    );
  }
}

class CourseSelectDiscountTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.continue = this.continue.bind(this);
  }
  continue() {
    this.props.navigate('courseSelectConfirm');
  }
  render() {
    // nothing in cart yet, should never occur
    if (this.props.items && this.props.items.length === 0) {
      console.log('[CourseSelectDiscountTotal] No items in cart.');
      return '';
    }

    let content = '';
    // user did not select anything new, don't display totals, just allow continue
    if (this.props.total && this.props.total.isComboDiscount) {

      // count selected items
      let title = '';
      if (this.props.total.items && this.props.total.items.length > 0) {
        if (this.props.total.items.length === 1) {
          title = `Total (1 Selected Item)`;
        }
        else {
          title = `Total (${this.props.total.items.length} Selected Items)`;
        }
      }

      content = (
        <div className='courseSelectDiscountTotalContainer'>
          <div className='courseSelectDiscountTotal'>
            <div className='courseSelectTotalLeft'>
              <h2>{title}</h2>
              <div>
                <span className='couseSelectTotalAmount'>
                  <span className='couseSelectTotalAmountBeforeDiscount'>
                    {toPrettyDollarsRound(this.props.total.totalWithoutDiscount)}
                  </span>
                  &nbsp;
                  <span>{toPrettyDollarsRound(this.props.total.total)}</span>
                </span>
                <div className='courseSelectTotalDiscountText'>
                  You'll save {toPrettyDollarsRound(this.props.total.discount)} on your purchase with our special offer!
                  {/* You'll save {toPrettyDollarsRound(this.props.total.discount)} on your purchase with our holiday sale! */}
                </div>
              </div>
            </div>
            <div className='courseSelectTotalRight'>
              <button className='pi4button pi4buttonLarge' onClick={this.continue}>Continue to Checkout</button>
            </div>
          </div>
        </div>
      );

    }

    else {
      content = (
        <div className='courseSelectDiscountTotalSkip'>
          <button className='pi4button pi4buttonLarge' onClick={this.continue}>No Thanks, Continue to Checkout</button>
        </div>
      );
    }

    return content;

  }
}

class CourseSelectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      total: {},
    };
    this.onCartChange = this.onCartChange.bind(this);
  }
  componentDidMount() {
    // INPROG get cart items into state
    let { items, total } = this.props.cart.getCart();
    // console.log(`[CourseSelectPage] Fetched initial cart.`, { items, total });
    this.setState({ items, total });
    // INPROG subscribe to cart changes
    // console.log(`[CourseSelectPage] Subscribing to cart changes...`);
    this.props.cart.subscribe(this.onCartChange);
  }
  onCartChange(cart) {
    // console.log(`[CourseSelectPage] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed
    this.setState({ items: cart.items, total: cart.total });
  }
  componentWillUnmount() {
    // INPROG unsubscribe from cart changes
    // console.log(`[CourseSelectPage] Unsubscribing from cart changes...`);
    this.props.cart.unsubscribe(this.onCartChange);
  }
  render() {

    let mainCourseSection = '';
    if (this.props.products.length > 0) {
      let mainCourse = this.props.products.find(p => p.type === enums.IndividualProduct.Type.MainCourse);
      mainCourseSection = (
        <div>
          <CourseSelectMainCourse course={mainCourse} cart={this.props.cart} />
        </div>
      );
    }

    let specialOffers = (
      <div className='newPurchaseSpecialOffers'>
        <img src='https://pi2-web.s3.amazonaws.com/img/purchase/special-offer-cat@2x.png' alt='Special Offer: Deep Dive Courses at 40% Off!' />
        <h3>Special Offer: Deep Dive Courses at 40% Off!</h3>
        <p>Add Product Management Foundations plus <strong>2 or more</strong> Deep Dive Courses (valued at $349 each) to your cart, and get <strong>40% off those Deep Dive Courses</strong>.</p>

        {/* <img src='https://pi2-web.s3.amazonaws.com/img/purchase/checkout_santa.png' alt='Holiday Bundle: 40% off Deep Dive Courses, PLUS save an additional $200!' />
        <h3>Holiday Bundle: 40% off Deep Dive Courses, PLUS save an additional $200!</h3>
        <p>
          Add Product Management Foundations plus 2 or more Deep Dive Courses to your cart, and get 40% off those Deep Dive Courses <strong>PLUS $200 off</strong> Product Management Foundations.
          Only available for a limited time!
        </p> */}

        {/* <img src='https://pi2-web.s3.amazonaws.com/img/purchase/checkout_santa.png' alt='Holiday Bundle: 40% off Deep Dive Courses, PLUS save an additional $200!' />
        <h3>Holiday Special: Choose your deal!</h3>
        <p>
          <strong>Deep Dive Courses at 40% Off:</strong> Add Product Management Foundations plus 2 or more Deep Dive Courses (valued at $349 each) to your cart, and get 40% off those Deep Dive Courses.
        </p>
        <p>
          <strong>Product Management Foundations at $200 Off</strong>: Discount Product Management Foundations only with code <strong>HOLIDAY</strong>!
        </p> */}
      </div>
    );

    let deepDiveCourseItems = [];
    if (this.props.products.length > 0) {
      let dcs = this.props.products.filter(p => p.type === enums.IndividualProduct.Type.Dessert);
      for (let dc of dcs) {
        deepDiveCourseItems.push(<CourseSelectDessertCourse key={dc.id} course={dc} cart={this.props.cart} />);
      }
    }
    let deepDiveCoursesSection = (
      <div className='deepDiveCoursesSelect'>
        <h2>Deep Dive Courses - <strong>$349</strong>/course </h2>
        <div className='newPurchaseSelectDessertCourses'>
          {deepDiveCourseItems}
        </div>
        
      </div>
    );

    let totalSection = '';
    let cart = this.props.cart.getCart();
    if (cart.total.items.length > 0) {
      totalSection = (
        <div className='courseSelectTotalContainer'>
          <CourseSelectTotal total={this.state.total} navigate={this.props.navigate} /> 
        </div>
      );
    }

    return (
      <div className="courseSelectPage">

        <h2>Choose Your Courses</h2>
        <p>
          Browse our course catalog below and add to cart. You can’t go wrong. All courses are accessible for one year from the purchase date, so you can learn on your own timeline.
        </p>

        <br />
        <br />

        {specialOffers}

        <br />
        <br />

        {mainCourseSection}

        {deepDiveCoursesSection}

        {totalSection}
      </div>
    );
  }
}

class CourseSelectDiscountCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let course = this.props.course;
    let cart = this.props.cart;
    let button = '';
    let isCourseAdded = cart.isItemInCart(course);
    if (isCourseAdded) {
      button = (
        <button className='pi4button pi4activeButton pi2uppercase' onClick={() => cart.remove(course)}
        >
          <img src='https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png' />
          Added to cart
        </button>
      );
    }
    else {
      button = (
        <button className='pi4button pi2uppercase' onClick={() => cart.add(course)}
        >Add to cart</button>
      );
    }

    return (
      <div className='courseSelectDiscountCourse'>
        <div className='courseSelectDiscountCourseLeft'>
          <img src={course.thumbnail} alt={course.name} />
        </div>
        
        <div className='courseSelectDiscountCourseMiddle'>
          <h3>{this.props.course.name}</h3>
          <p>{this.props.course.description}</p>
        </div>

        <div className='courseSelectDiscountCourseRight'>
          <span className='courseSelectDiscountCourseAmount'>
            <span className='courseSelectDiscountCourseAmountBeforeDiscount'>
              {toPrettyDollarsRound(this.props.course.price.amount)}
            </span>
            &nbsp;
            {toPrettyDollarsRound(this.props.course.priceComboOffer.amount)}
          </span>

          <br />
          {button}

        </div>
      </div>
    );
  }
}

class CourseSelectDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      availableProducts: [],
      total: {},
    };
    this.onCartChange = this.onCartChange.bind(this);
  }
  componentDidMount() {

    let cart = this.props.cart;

    // get cart items into state
    let { items, total } = cart.getCart();
    // console.log(`[CourseSelectDiscount] Fetched cart state.`, { items, total });
    this.setState({ items, total });

    // subscribe to cart changes
    // console.log(`[CourseSelectDiscount] Subscribing to cart changes...`);
    this.props.cart.subscribe(this.onCartChange);
  
    // determine available items (not already in cart)
    let dessertsNotInCart = this.props.products.filter(p =>
      p.type === enums.IndividualProduct.Type.Dessert && !cart.isItemInCart(p)
    );
    this.setState({ availableProducts: dessertsNotInCart })
  }
  componentWillUnmount() {
    // unsubscribe from cart changes
    // console.log(`[CourseSelectDiscount] Unsubscribing from cart changes...`);
    this.props.cart.unsubscribe(this.onCartChange);
  }
  onCartChange(cart) {
    // console.log(`[CourseSelectDiscount] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed
    this.setState({ items: cart.items, total: cart.total });
  }
  render() {

    let courses = [];

    for (let product of this.state.availableProducts) {
      courses.push(
        <CourseSelectDiscountCourse key={product.id} course={product} cart={this.props.cart}/>
      );
    }

    return (
      <div className='courseSelectDiscountPage' id='courseSelectDiscountPage'>
        <h2>Don’t miss out on a discount!</h2>
        <p>Add one or more courses, and get <strong>40% off</strong> each Deep Dive course in your cart.</p>
        {/* <p>
          Just one more course grants you <strong>40% off</strong> of all Deep Dive Courses in your cart <strong>AND $200 off</strong> Product Management Foundations!
        </p> */}
        <div className='courseSelectDiscountCourses'>
          {courses}
        </div>
        <br />
        <CourseSelectDiscountTotal total={this.state.total} navigate={this.props.navigate} />
      </div>
    );
  }
}

class CourseSelectConfirm extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      total: null,
    };
    this.onCartChange = this.onCartChange.bind(this);
    this.continue = this.continue.bind(this);
  }
  continue() {
    this.props.navigate('signup');
  }
  componentDidMount() {
    // INPROG get cart items into state
    let { items, total } = this.props.cart.getCart();
    console.log(`[CourseSelectConfirm] Fetched initial cart.`, { items, total });
    this.setState({ items, total });
    // INPROG subscribe to cart changes
    console.log(`[CourseSelectConfirm] Subscribing to cart changes...`);
    this.props.cart.subscribe(this.onCartChange);
  }

  onCartChange(cart) {
    console.log(`[CourseSelectConfirm] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed
    this.setState({ items: cart.items, total: cart.total });
  }

  componentWillUnmount() {
    // INPROG unsubscribe from cart changes
    console.log(`[CourseSelectConfirm] Unsubscribing from cart changes...`);
    this.props.cart.unsubscribe(this.onCartChange);
  }

  render() {

    let items = this.state.items;
    let total = this.state.total;

    if (!this.state.items || !this.state.total) {
      return '';
    }

    // render the courses in cart
    let courses = [];
    for (let course of items) {
      courses.push(
        <CourseSelectConfirmItem key={course.id} course={course} total={total} cart={this.props.cart} />
      );
    }

    return (
      <div className='courseSelectConfirmPage' id='courseSelectConfirmPage'>
        <h2>You’re on your way to product excellence.</h2>
        <h3>Your Courses</h3>
        {courses}
        <div className='courseSelectReturn'>
          Want to add courses? <a className='actionLink' onClick={() => this.props.navigate('courseSelect')}>Return to Course Selection</a>
        </div>

        <DiscountForm form={this.props.couponForm} onCouponChange={this.props.onCouponChange} onCouponSubmit={this.props.onCouponSubmit} total={total} cart={this.props.cart} /> 

        <CourseSelectConfirmTotal total={total} items={items} />

        <div className='courseSelectConfirmContinue'>
          <button className='pi4button pi4buttonLarge' onClick={() => this.continue()}>Continue</button>
        </div>
      </div>
    );
  }
}

class DiscountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: null, // null, 'submitting', 'invalid', 'applied' (derive if it's bundle or coupon from total)
    };
    this.onCouponCodeChange = this.onCouponCodeChange.bind(this);
  }
  componentDidMount() {
    // update state based on total
    let total = this.props.total;
    console.log('[DiscountForm] componentDidMount() running. Total:', { total, props: this.props, state: this.state });
    if (total.isComboDiscount) {
      this.setState({ state: 'bundle' });
    }
  }
  onCouponCodeChange(e) {
    let newCouponCode = e.target.value;
    // remove spaces, convert small caps to uppercase
    newCouponCode = newCouponCode.trim().toUpperCase();
    this.props.onCouponChange({ couponCode: newCouponCode });
    // this.setState({ couponCode: newCouponCode });
  }
  render() {
    
    console.log('[DiscountForm] render() called.', { state: this.state, props: this.props });

    if (this.props.form.stage === null) {
      if (this.props.total.discount === 0) {
        return (
          <div className='discountForm'> 
            <h3>+ Add Coupon/Promo Code</h3>
            <div className='discountFormContainer'>
              <div className='discountFormLeft'>
                <div className='pi2form'>
                  <input type='text' className='' id='input-coupon' name='coupon' value={this.props.form.couponCode} onChange={this.onCouponCodeChange} placeholder='Enter your code here' />
                </div>
              </div>
              <div className='discountFormRight'>
                <button className='pi4button pi4buttonLarge' onClick={() => this.props.onCouponSubmit()}>Apply</button>
              </div>
            </div>
          </div>
        );
      }

      // combo ombo coco jumbo
      else if (this.props.total.isComboDiscount) {
        return (
          <div className='discountForm'>
            <h3>+ Add Coupon/Promo Code</h3>
            <div className='discountFormContainer'>
              <div className='discountFormLeft'>
                <div className='pi2form'>
                  <input type='text' className='' id='input-coupon' disabled='disabled' name='coupon' value='40% Off Specialty Courses' />
                  {/* <input type='text' className='' id='input-coupon' disabled='disabled' name='coupon' value='HOLIDAY BUNDLE' /> */}
                </div>
              </div>
              <div className='discountFormRight'>
                <button className='pi4button pi4buttonLarge pi4activeButton' onClick={this.props.lol}>
                  <img src="https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png" />
                  Applied!
                </button>
              </div>
            </div>
            <p>40% Off Specialty Courses Applied: only one discount can be applied per purchase. You’re currently getting our best deal with the special offer!</p>
          </div>
        );
      }
    }

    if (this.props.form.stage === 'applied') { 
      // TODO check if this ever happens
      // bundle discount code applied
      if (this.props.total.isComboDiscount) {
        return '';
        // return (
        //   <div className='discountForm'>
        //     <h3>+ Add Coupon/Promo Code</h3>
        //     <div className='discountFormContainer'>
        //       <div className='discountFormLeft'>
        //         <div className='pi2form'>
        //           <input type='text' className='' id='input-coupon' disabled='disabled' name='coupon' value={this.state.couponCode} onChange={this.onCouponCodeChange} placeholder='Enter your code here' />
        //         </div>
        //       </div>
        //       <div className='discountFormRight'>
        //         <button className='pi4button pi4buttonLarge' disabled='disabled' onClick={this.props.lol}>
        //           <img src="https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png" />
        //           Applied!
        //         </button>
        //       </div>
        //     </div>
        //     <p>40% Off Specialty Courses Applied: only one discount can be applied per purchase. You’re currently getting our best deal with the special offer!</p>
        //   </div>
        // );
      }
      // coupon discount code applied
      return (
        <div className='discountForm'>
          <h3>+ Add Coupon/Promo Code</h3>
          <div className='discountFormContainer'>
            <div className='discountFormLeft'>
              <div className='pi2form'>
                <input type='text' className='' id='input-coupon' disabled='disabled' name='coupon' value={this.props.form.couponCode}/>
              </div>
            </div>
            <div className='discountFormRight'>
              <button className='pi4button pi4buttonLarge pi4activeButton' onClick={this.props.lol}>
                <img src="https://pi2-web.s3.amazonaws.com/img/purchase/added@2x.png" />
                Applied!
              </button>
            </div>
          </div>
          {/* <p>Coupon code applied.</p> */}
        </div>
      );
    }

    if (this.props.form.stage === 'submitting') { 
      return (
        <div className='discountForm'>
          <h3>+ Add Coupon/Promo Code</h3>
          <div className='discountFormContainer'>
            <div className='discountFormLeft'>
              <div className='pi2form'>
                <input type='text' className='' id='input-coupon' disabled='disabled' name='coupon' value={this.props.form.couponCode}/>
              </div>
            </div>
            <div className='discountFormRight'>
              <button className='pi4button pi4buttonLarge' disabled onClick={this.props.lol}>
                Loading...
              </button>
            </div>
          </div>
          {/* <p>Coupon code applied.</p> */}
        </div>
      );
    }

    if (this.props.form.stage === 'invalid') { 
      return (
        <div className='discountForm'>
          <h3>+ Add Coupon/Promo Code</h3>
          <div className='discountFormContainer'>
            <div className='discountFormLeft'>
              <div className='pi2form'>
                <input type='text' className='invalid' id='input-coupon' name='coupon' value={this.props.form.couponCode} onChange={this.onCouponCodeChange} placeholder='Enter your code here' />
              </div>
            </div>
            <div className='discountFormRight'>
              <button className='pi4button pi4buttonLarge' onClick={() => this.props.onCouponSubmit()}>Apply</button>
            </div>
          </div>
          <p className='validationMessage'>Please enter a valid coupon/promo code.</p>
        </div>
      );
    }

    return '';

  }
}


// <CourseSelectConfirmItem course={course} total={this.state.total} />
class CourseSelectConfirmItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    let total = this.props.total;
    let course = this.props.course;

    let price = '';
    // if (total.isComboDiscount && course.type === enums.IndividualProduct.Type.Dessert) {
    if (total.isComboDiscount && course.priceComboOffer.amount < course.price.amount) {
      price = (
        <h5>
          Price:&nbsp;
          <i>{toPrettyDollarsRound(course.price.amount)}</i>
          &nbsp;
          <strong>{toPrettyDollarsRound(course.priceComboOffer.amount)}</strong>
        </h5>
      );
    }
    else {
      price = (
        <h5>
          Price:&nbsp;
          <strong>{toPrettyDollarsRound(course.price.amount)}</strong>
        </h5>
      );
    }

    let discountLabel = '';
    // if (total.isComboDiscount && course.type === enums.IndividualProduct.Type.Dessert) {
    if (total.isComboDiscount && course.priceComboOffer.amount < course.price.amount) {
      discountLabel = (
        // <span className='courseSelectDiscountLabel'><strong>40% Off</strong></span>
        <span className='courseSelectDiscountLabel'><strong>{course.priceComboOffer.label}</strong></span>
      );
    }


    return (
      <div key={course.id} className='courseSelectConfirmItem'>
        <div className='courseSelectConfirmItemLeft'>
          <img src={course.thumbnail} alt={course.name} />
        </div>
        <div className='courseSelectConfirmItemRight'>
          <h4>{course.name} {discountLabel}</h4>
          {price}
          <div className='removeCourseBox'>
            <a className='actionLink' onClick={() => this.props.cart.remove(course)}>Remove</a>
          </div>
        </div>
      </div>
    );

  }
}

// <CourseSelectConfirmTotal total={total} />
class CourseSelectConfirmTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total = this.props.total;
    let items = this.props.items;

    // count selected items
    let orderSummary = '';
    if (items.length > 0) {
      if (items.length === 1) {
        orderSummary = `1 Item`;
      }
      else {
        orderSummary = `${items.length} Items`;
      }
    }

    let discount = '';
    let discountLabel = '';
    if (total.isComboDiscount) {
      discountLabel = (
        <span className='courseSelectConfirmTotalDiscount'>40% Off Speciality Courses</span> 
        // <span className='courseSelectConfirmTotalDiscount'>40% off Deep Dive Courses + $200 Off PM Foundations</span>
      );
    }
    else if (total.isCouponDiscount) {
      discountLabel = (
        <span className='courseSelectConfirmTotalDiscount'>{total.discountCouponCode}</span> 
      );
    }

    if (total.discount) {
      discount = (
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Discount&nbsp;
            {discountLabel}
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            -{toPrettyDollarsRound(total.discount)}
          </div>
        </div>
      );
    }

    return (
      <div className='courseSelectConfirmTotal'>
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            <strong>Order Summary</strong>
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            <strong>{orderSummary}</strong>
          </div>
        </div>

        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Subtotal
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollarsRound(total.totalWithoutDiscount)}
          </div>
        </div>

        {discount}

        <div className='courseSelectConfirmTotalRow courseSelectConfirmTotalTotal'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Your Total
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollarsRound(total.total)}
          </div>
        </div>
      </div>
    );
  }
}

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false, // TODO deprecated?
      isUserAlreadyThere: false,
    };
    this.submit = this.submit.bind(this);
  }
  async submit() {
    try {
      // attempt validation
      let tree = this.props.validate(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(tree);
      this.props.onChange({ validate: true });
      if (isHealthy) {
        // console.log('[SignupForm] Tree is healthy!');

        // TODO check here if the email is already in use
        let isUserAlreadyThere = await validateEmail(this.props.form.email);

        this.setState({ isUserAlreadyThere: isUserAlreadyThere.result });

        // if user already exists, prevent submission
        // user doesn't already exist, so continue
        if (isUserAlreadyThere.result === true) {
          return;
        }

        // INPROG either navigate to invoice form, or submit to server to start stripe
        if (this.props.form.invoice) {
          // console.log('[SignupForm] Invoice should be generated, heading to invoice view');
          this.props.navigate('invoice');
        }
        else {
          // console.log('[SignupForm] Invoice should not be generated, heading straight to submission');
          this.props.submit();
        }

      }
      else {
        console.log('[SignupForm] Tree is not healthy.');
      }
    } catch(e) {
      console.error(`[SignupForm] Error handling submit().`, e);
    }
  }


  render() {

    let form = this.props.form;
    let validate = this.props.validate;
    let onChange = this.props.onChange;

    let termsLabel = <span>I agree to <a href='/p/click-through' target='_blank'>Product Institute's Terms</a></span>;

    // https://www.figma.com/file/43dgJMptWcAI2HgMh9e5sq/Product-Institute---Purchase-Flow-%2F-V2
    let emailValidationMessage = '';
    if (this.state.isUserAlreadyThere) {
      emailValidationMessage = (
        <p style={{ color: '#f00', margin: '5px 0 20px   0' }}>
          We're sorry, but that email is already taken.
          Please try another email to continue.
          If you continue to have problems, please contact <a style={{ color: '#f00' }} href='mailto:info@productinstitute.com'>info@productinstitute.com</a>.
        </p>
      );
    }

    let emailClass = validate('email');
    if (this.state.isUserAlreadyThere) {
      emailClass = 'invalid';
    }

    return (
      <div className='newPurchaseSignupForm' id='newPurchaseSignupForm'>
        <h2>Sign Up</h2>
        <p>Create a Product Institute account to complete check out. An account is required to sign in and view your courses.</p>
        <div className='pi2form'>

          <div className="formRow">
            <label htmlFor="input-name">Name</label>
            <input type='text' className={` ${validate('name')}`} id='input-name' name='name' value={form.name} onChange={e => onChange({ name: e.target.value })} placeholder='e.g. John Smith' />
          </div>

          <div className="formRow">
            <label htmlFor="input-email">Email Address</label>
            <input type='text' className={emailClass} id='input-email' name='email' value={form.email} onChange={e => onChange({ email: e.target.value })} placeholder='e.g. jsmith@company.com' />
            {emailValidationMessage}
          </div>

          {/* <div className="formRow">
            <label htmlFor="input-password">Password</label>
            <input type='password' className={`${validate('password')}`} id='input-password' name='password' value={form.password} onChange={e => onChange({ password: e.target.value })} placeholder='Password' />
          </div>

          <div className="formRow">
            <label htmlFor="input-repeatPassword">Re-enter Password</label>
            <input type='password' className={`${validate('repeatPassword')}`} id='input-repeatPassword' name='repeatPassword' value={form.repeatPassword} onChange={e => onChange({ repeatPassword: e.target.value })} placeholder='Repeat password' />
          </div> */}

          <div className='generateIndividualInvoiceBox'>
            <div className='generateIndividualInvoiceBoxHeading'>
              <h3>Do you need an invoice?</h3>
              <FormYesNo value={form.invoice} onChange={value => onChange({ invoice: value })} />
            </div>
            <p>If you need an invoice for reimbursement, select “Yes”. We will ask for additional information to generate your invoice.</p>
          </div>

          <div className="formRow">
            <label>
              <FormCheckbox isChecked={form.terms} label={termsLabel} onChange={value => onChange({ terms: value })} />
            </label>
          </div>

          <div className="formRow">
            <label>
              <FormCheckbox isChecked={form.marketing} label="I agree to receive instructional and promotional emails" onChange={marketing => onChange({ marketing })} />
            </label>
          </div>

          <div className="formRow newPurchaseSignupFormSubmit">
            {
              form.terms ?
                <button className='pi4button pi4buttonLarge' onClick={this.submit}>Create Account</button> : 
                <button className='pi4button pi4buttonLarge' disabled>Create Account</button>
            }

          </div>

        </div>
      </div>
    );
  }
}

// <InvoiceForm charge={this.state.invoice.charge} onSubmit={this.onInvoiceSubmit} />
class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,
    };
    this.submit = this.submit.bind(this);
  }

  submit() {
    try {
      // attempt validation
      let tree = this.props.validate(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(tree);
      this.props.onChange({ validate: true });

      if (isHealthy) {
        // console.log('[InvoiceForm] Tree is healthy.');
        this.props.submit();
      }
      else {
        // console.log('[InvoiceForm] Tree is not healthy.');
      }
    }
    catch(e) {
      console.error(`[InvoiceForm] Error handling submit().`, e);
    }
  }

  render() {

    let form = this.props.form;
    let validate = this.props.validate;
    let onChange = this.props.onChange;

    return (

      <div className='newPurchaseInvoiceForm' id='newPurchaseInvoiceForm'>
        <h2>Invoice Information</h2>

        <p className='newPurchaseSignupFormParagraph'>
          Fill out the information below and we’ll generate an invoice for you. Don’t need one after all? You can <a onClick={() => this.props.submit()}>skip this step</a>.
        </p>

        <div className='pi2form'>

          <div className="formRow">
            <label htmlFor="input-fullname">Name (who should this invoice be made out to):</label>
            <input type='text' className={` ${validate('name')}`} id='input-fullname' name='name' value={form.name} onChange={e => onChange({ name: e.target.value })} placeholder='e.g. John Smith' />
          </div>

          <div className="formRow">
            <label htmlFor="input-email">Email:</label>
            <input type='email' className={` ${validate('email')}`} id='input-email' name='email' value={form.email} onChange={e => onChange({ email: e.target.value })} placeholder='name@company.com' />
          </div>

          <div className="formRow">
            <label htmlFor="input-company-name">Company name:</label>
            <input type='text' className={` ${validate('companyName')}`}  id='input-company-name' name='companyName' value={form.companyName} onChange={e => onChange({ companyName: e.target.value })} placeholder='e.g. Acme' />
          </div>

          <div className="formRow">
            <label htmlFor="input-company-vat">VAT number: (if applicable)</label>
            <input type='text' className='' id='input-company-vat' name='input-vat' value={form.companyVAT} onChange={e => onChange({ companyVAT: e.target.value })} placeholder='VAT #' />
          </div>

          <div className="formRow">
            <label htmlFor="input-message">Company address:</label>
            <textarea className={` ${validate('companyAddress')}`} id='company-address' name='company-address' value={form.companyAddress} onChange={e => onChange({ companyAddress: e.target.value })} placeholder="e.g. &#x000D;&#x000A;123 West Court &#x000D;&#x000A;Westbury, NY 11590 &#x000D;&#x000A;United States" />
          </div>


          <div className="formRow newPurchaseInvoiceFormSubmit">
            <button className='pi4button pi4buttonLarge' onClick={() => this.submit()}>Continue</button>
            <br />
            <br />
            <a onClick={() => this.props.submit()}>Skip this step</a>
          </div>

          <br />
          <br />

        </div>

      </div>

    );
  }
}



export default Pricing;


class CompanyOnboarding2ThankYouStripe extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='pi2thanks'>
        <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
        <h1>Purchase Complete</h1>
        <p>
          Your purchase is complete.
          <br />
          You should receive an email with your invoice soon.
          <br />
          Look out for onboarding instructions sent to your email within 48 hours.
          <br />
          Thank you for choosing Product Institute!
        </p>
        <a href='https://productinstitute.com'>
          <button className='pi4button'>Return to Product Institute</button>
        </a>
      </div>
    );
  }
}


