
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';



// we'll use native PDF embed now, instead of PDF.js
// this will not work on android chrome (?) so we'll need an alternative option there
// TODO need to fix how file upload works for this component/block
// https://stackoverflow.com/questions/291813/recommended-way-to-embed-pdf-in-html
// https://github.com/mozilla/pdf.js#online-demo

class CourseBlockDocument extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    return (
      <div className="pi3courseDocument">
        <embed src={block.url} type="application/pdf" />
      </div>
    );
  }
}

export default CourseBlockDocument;