
import React from 'react';

export const GInputText = function (props) {

  return (<>
    <span className="pi3form">
      <input
        type="text"
        placeholder={props?.placeholder}
        ref={(refEl) => { props?.onElement?.(refEl); }}
        value={props?.value ?? ''}
        onChange={(e) => { props?.onChange?.(e.target.value); }}
        onKeyPress={(e) => {
          if (e.charCode == 13 && props?.onSubmit) {
            event.preventDefault();
            event.stopPropagation();
            props?.onSubmit?.(e.target.value);
            return;
          }
        }}
        style={{
          ...props?.style,
        }}
      />
    </span>
  </>);

};
