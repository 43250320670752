import React from 'react';

class PurchaseFlowCloud extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <header className="newPurchasePricingHeader">
        <br />
        {/* <h1>Career-long learning, all in one place.</h1> */}
        <h1>The no-brainer product for brainy product people.</h1>
        <br />
        {/* <p>Join Product Institute today and take your first step towards product excellence. We guarantee lively content, valuable frameworks, and continuous growth, entirely online and at your own pace.</p> */}
        <p>Select your Product Institute courses today and take the first step towards product excellence. You’ll be granted a year of access to one-of-a-kind content, valuable frameworks and tools you can easily apply to your daily product work, and lessons you can trust to level you up.</p>
      </header>
    );
  }
}

export default PurchaseFlowCloud;