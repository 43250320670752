// form validation helpers


// how does validation work?

// merge(newData) {
//   let data = _.extend(this.state.entity, newData);
//   this.setState({
//     entity: data,
//     tree: this.validate(null, data)
//   });
// }

// validate(attribute, newData) {
//   let data = newData ? newData : this.state.influencer;
//   // if attribute is provided, return empty string or CSS class to add to invalid field
//   if (attribute) {
//     if (this.state.validate) {
//       // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
//       if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
//         return 'invalid';
//       }
//       return '';
//     } else {
//       return '';
//     }
//   } else {
//     // generate validation tree
//     let tree = {
//       name: helpers.validateString(data.name) ? null : 'Name is required.',
//       questions: []
//     };
//     if (data.questions && data.questions.length > 0) {
//       for (let item of data.questions) {
//         tree.questions.push({
//           question: helpers.validateString(item.question) ? null : 'Question required.',
//           answer: helpers.validateString(item.answer) ? null : 'Answer required.'
//         });
//       }
//     }
//     return tree;
//   }
// }





'use strict';


import { DateTime } from 'luxon';


function validateString(value, required = true) {
  // don't allow undefined values
  if (value === undefined) {
    return false;
  }
  if (required) {
    // don't allow null values
    if (value === null) {
      return false;
    }
    // type check: don't allow anything that's not a string
    if (typeof value !== 'string') {
      return false;
    }
    value = value.trim();
    // don't allow blank entries
    if (value === '') {
      return false;
    }
    // all checks passed
    return true;
  } else {
    // since string is not required, allow nulls or (empty) strings
    if (value === null || typeof value === 'string') {
      //all checks passed
      return true;
    }
    return false;
  }
}

function validateInt(value, required = true, allowZero = true, allowNegative = false) {
  // don't allow undefined or NaN values
  if (value === undefined || isNaN(value)) {
    return false;
  }
  // type check: don't allow anything that's not a number
  if (typeof value !== 'number') {
    return false;
  }
  // integers should be integers
  if (value % 1 !== 0) {
    return false;
  }
  if (required) {
    // if zero is not allowed, check
    if (!allowZero && value === 0) {
      return false;
    }
    // if negative numbers are not allowed, check
    if (!allowNegative && value < 0) {
      return false;
    }
  } 
  // all checks passed
  return true;
}

function validateFloat(value, required = true, allowZero = true, allowNegative = false) {
  // don't allow undefined, NaN or Infinity values
  if (value === undefined || isNaN(value) || value === Infinity) {
    return false;
  }
  // type check: don't allow anything that's not a number
  if (typeof value !== 'number') {
    return false;
  }
  if (required) {
    // if zero is not allowed, check
    if (!allowZero && value === 0) {
      return false;
    }
    // if negative numbers are not allowed, check
    if (!allowNegative && value < 0) {
      return false;
    }
  } 
  // all checks passed
  return true;
}

function validateObject(value, required = true, allowBlank = false) {
  // don't allow undefined values
  if (value === undefined) {
    return false;
  }
  if (typeof value === 'object') {
    // don't allow arrays
    if (value && value.constructor === Array) {
      return false;
    }
    if (required) {
      // if value is required, don't allow nulls
      if (value === null) {
        return false;
      }
      // if we don't allow blank objects, check keys
      if (!allowBlank && Object.keys(value).length === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    // certainly not an object (or null, because typeof null === 'object')
    return false;
  }
}

// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
function validateEmail(email, required = true) {
  if (!required && (email === null || email === '')) {
    // email not required, so null and blank values are valid
    return true;
  }

  let isValid = String(email)
    .match(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

  return isValid;
}


// this one is opposite - return null if VALID, return message if invalid
function validateEmailDomain(email, domains) {
  let isValidFormat = String(email)
    .match(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

  if (domains.length === 0) {
    if (isValidFormat) {
      return null;
    }
    else {
      return 'Email is invalid.';
    }
  }

  let isValidDomain = false;
  for (let domain of domains) {
    if(email.indexOf(`@${domain}`) > -1) {
      isValidDomain = true;
      break;
    }
  }

  if (!isValidFormat) {
    return 'Email is invalid.';
  }
  if (!isValidDomain) {
    return 'Email must belong to company.';
  }

  return null;
}


/**
 * Validate ISO8601 (string) date.
 * @param {string} value ISO8601 formatted string.
 * @param {bool} required Require non-null value. 
 */
function validateDate(value, required = true) {
  if (value === undefined) {
    console.log('date undefined');
    return false;
  }
  if (typeof value === 'string') {
    // if not required & null, return true
    if (!required && value === null) {
      //console.log('date not required and null');
      return true;
    }
    // var t = moment(value).isValid();
    var t = new DateTime.fromISO(value).isValid;
    //console.log('is date valid?', t);
    return t;
  } else {
    // not a string, so not a ISO8601 string either
    return false;
  }
}

function validatePassword(value, minLength = 10) {
  if (typeof value === 'string') {
    // if not required & null, return true
    if (value.length >= minLength) {
      return true;
    }
    return false;
  } else {
    // not even a string
    return false;
  }
}

function isTreeHealthy(node) {
  if (node === null || node === false) {
    //console.log('Node is null or false.', node);
    return true;
  } else if (typeof node === 'string') {
    //console.log('Node is a string.', node);
    return node.length > 0 ? false : true;
  } else if (node.constructor === Array) {
    //console.log('Node is an Array.', node);
    for (let i = 0; i < node.length; i++) {
      if (!isTreeHealthy(node[i])) {
        return false;
      }
    }
    return true;
  } else if (typeof node === 'object') {
    //console.log('Node is an object.', node);
    let keys = Object.keys(node);
    for (let i = 0; i < keys.length; i++) {
      if (!isTreeHealthy(node[keys[i]])) {
        return false;
      }
    }
    return true;
  } else {
    console.log('Node is of an unknown type.', node);
    throw new Error('Node is of an unknown type.');
  }
}

export default {
  validateString,
  validateEmail,
  validateEmailDomain,
  validateInt,
  validateFloat,
  validateObject,
  validateDate,
  validatePassword,
  isTreeHealthy,
};
